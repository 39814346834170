import * as State from './state-keys'
import * as Getters from './getter-types'
import * as Mutations from './mutation-types'
import * as Actions from './action-types'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions
}

export { State, Getters, Mutations, Actions }
