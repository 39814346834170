export const DocumentStatus = Object.freeze({
  None: 0,
  Uploaded: 1,
  Rejected: 2,
  Validated: 3
})

export const DocumentTypes = Object.freeze({
  Photo: 0,
  Identity: 1,
  HealthInsuranceEvidence: 2,
  CurriculumVitae: 3,
  BankStatement: 4
})

export const NdfStatus = Object.freeze({
  Pending: 0,
  Validated: 1,
  Rejected: 2,
  Payed: 3
})

export const VatRates = Object.freeze([0.2, 0.13, 0.1, 0.085, 0.055, 0.021, 0])

export const VatZone = Object.freeze({
  France: 0,
  Europe: 1,
  Other: 2
})

export const PhoneUsage = Object.freeze({
  Mixed: 0,
  ProOnly: 1
})

export const UserStatus = Object.freeze({
  New: 0,
  Modified: 1,
  Rejected: 2,
  Validated: 3
})

export const MODE = Object.freeze({
  CREATE: 'create',
  EDIT: 'edit'
})
