<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-row>
      <b-col cols="9">
        <b-form-group label="Nom" horizontal>
          <b-input v-model="category.Name" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Code" horizontal>
          <b-input v-model="category.Code" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Description" horizontal>
          <b-textarea v-model="category.Description" rows="3" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="6">
        <b-form-group label="Compte TVA" horizontal>
          <b-input v-model="category.VatAccount" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Part TVA" horizontal>
          <b-input
            type="number"
            min="0"
            max="100"
            v-model="vatRatio"
            v-show="!!category.VatAccount"
          />
          <span v-show="!category.VatAccount">-</span>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Taux applicables" horizontal>
          <b-form-radio-group>
            <b-row>
              <b-col
                v-for="{ text, value, disabled } in options"
                :key="value"
                class="text-center"
              >
                <b-badge
                  :variant="isSelected(value) ? 'dark' : 'light'"
                  class="border border-dark"
                  style="cursor: pointer"
                  @click="onRateClicked(value, $event)"
                >
                  {{ text }}
                </b-badge>
                <b-form-radio
                  v-model="defaultRate"
                  name="default"
                  :value="value"
                  :disabled="disabled"
                  class="default-rate-btn m-0"
                  @click="onDefaultClicked"
                  plain
                  ><span v-b-tooltip.hover.bottom title="Taux par défaut"
                    ><fa-icon
                      :icon="[
                        value == defaultRate ? 'fas' : 'far',
                        'star'
                      ]" /></span
                ></b-form-radio>
              </b-col>
            </b-row>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="6">
        <b-form-group label="Montant minimum" horizontal>
          <b-input type="number" v-model="category.MinAmount" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Montant maximum" horizontal>
          <b-input type="number" v-model="category.MaxAmount" />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { VatRates } from '@/assets/js/Constants'

export default {
  name: 'CategoryDetails',
  props: {
    category: { type: Object, required: true }
  },
  data() {
    return {
      selectedRates: [],
      defaultRate: null,
      validated: false
    }
  },
  computed: {
    vatRatio: {
      get() {
        return (this.category.VatRatio * 100).toFixed()
      },
      set(value) {
        this.category.VatRatio = value / 100
      }
    },
    options() {
      return VatRates.map(e => ({
        text: this.formatVatRate(e),
        value: e,
        disabled: !this.isSelected(e)
      }))
    }
  },
  watch: {
    defaultRate(value) {
      if (value !== undefined && value !== null) {
        let rate = this.selectedRates.find(r => r.default)

        if (!!rate) {
          rate.default = false
        }

        rate = this.selectedRates.find(r => r.rate == value)
        rate.default = true
      }
    }
  },
  mounted() {
    this.selectedRates = (this.category.ApplicableRates ?? [...VatRates]).map(
      r => ({
        rate: Math.abs(r),
        default: 1 / r < 0 // 1 / -0 == -Infinity
      })
    )
    this.defaultRate = this.selectedRates.find(r => r.default)?.rate
  },
  methods: {
    isSelected(rate) {
      return !!this.selectedRates.find(r => r.rate == rate)
    },
    formatVatRate(rate) {
      return `${rate * 100} %`.replace('.', ',')
    },
    onRateClicked(rate, evt) {
      evt.preventDefault()
      const idx = this.selectedRates.findIndex(r => r.rate == rate)

      if (idx > -1 && this.selectedRates.length > 1) {
        if (this.selectedRates[idx].default) {
          this.defaultRate = null
        }

        this.selectedRates.splice(idx, 1)
      } else {
        this.selectedRates.push({
          rate,
          default: false
        })
      }
    },
    onDefaultClicked(...args) {
      console.debug(args)
    },
    validate() {
      this.validated = false

      let valid = this.$refs.form.checkValidity()

      if (valid) {
        this.category.ApplicableRates = this.selectedRates.map(r =>
          r.default
            ? r.rate == 0
              ? '-0' // Otherwise -0 is serialized as 0
              : -r.rate
            : r.rate
        )
      }

      this.validated = true

      return valid
    }
  }
}
</script>
<style lang="scss" scoped>
.default-rate-btn {
  padding-left: 0;

  ::v-deep .custom-control-label::before {
    display: none;
  }
}
</style>
