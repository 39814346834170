<template>
  <small class="remote-icon">
    <fa-icon
      icon="laptop-house"
      :class="innerActive ? 'text-primary' : 'text-secondary'"
      title="Télétravail"
    />
    <!-- @click="toggleActive" -->
  </small>
</template>

<script>
export default {
  name: 'RemoteIcon',
  model: {
    prop: 'active',
    event: 'changed'
  },
  props: {
    active: { type: Boolean, default: false }
  },
  data() {
    return {
      innerActive: this.active
    }
  },
  methods: {
    toggleActive() {
      this.innerActive = !this.innerActive
      this.$emit('changed', this.innerActive)
    }
  }
}
</script>

<style lang="scss" scoped>
.remote-icon {
  .text-secondary {
    opacity: 0.5;
  }

  svg {
    // cursor: pointer;
  }
}
</style>
