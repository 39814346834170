<template>
  <span
    class="user-visibility-filter"
    :class="{
      'text-success': value === true,
      'text-warning': value === false,
      'text-secondary': value === undefined
    }"
    @click="toggleVisibilityFilter"
  >
    <fa-icon :icon="['far', this.value === false ? 'eye-slash' : 'eye']" />
  </span>
</template>
<script>
export default {
  name: 'UserStatusFilter',
  props: {
    value: { type: Boolean }
  },
  methods: {
    toggleVisibilityFilter() {
      if (this.value === true) {
        this.$emit('input', false)
      } else if (this.value === false) {
        this.$emit('input', undefined)
      } else {
        this.$emit('input', true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user-visibility-filter {
  cursor: pointer;
}
</style>
