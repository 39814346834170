<template>
  <b-form-group
    invalid-feedback="Au moins un convive doit être renseigné."
    :state="state"
  >
    <template #label>
      <b-form-row>
        <b-col cols="11">Convives</b-col>
        <b-col cols="1" class="text-center">
          <small title="Ajouter" v-b-tooltip.hover.left
            ><fa-icon
              icon="plus"
              class="text-primary btn-icon"
              @click="addGuest"
          /></small>
        </b-col>
      </b-form-row>
    </template>
    <template v-if="readonly">
      <b-form-row
        v-for="({ Name, Company, JobTitle }, idx) in guests"
        :key="idx"
        size="sm"
      >
        <b-col class="no-wrap">
          <small :title="Name" v-b-tooltip.hover>{{ Name }}</small>
        </b-col>
        <b-col class="no-wrap">
          <small :title="Company" v-b-tooltip.hover>{{ Company }}</small>
        </b-col>
        <b-col class="no-wrap">
          <small :title="JobTitle" v-b-tooltip.hover>{{ JobTitle }}</small>
        </b-col>
      </b-form-row>
    </template>
    <template v-else>
      <b-form-row v-for="(guest, idx) in guests" :key="idx" class="mb-1">
        <b-col>
          <b-form-input
            v-model="guest.Name"
            placeholder="NOM Prénom"
            size="sm"
            required
          />
        </b-col>
        <b-col>
          <b-form-input
            v-model="guest.Company"
            placeholder="Société"
            size="sm"
            required
          />
        </b-col>
        <b-col>
          <b-form-input
            v-model="guest.JobTitle"
            placeholder="Poste"
            size="sm"
            required
          />
        </b-col>
        <b-col cols="1" class="text-center">
          <small
            v-if="!readonly && guestList.length > 1"
            title="Supprimer"
            class="align-middle"
            v-b-tooltip.hover.left
            ><fa-icon
              icon="times"
              class="text-danger btn-icon"
              @click="removeGuest(idx)"
          /></small>
        </b-col>
      </b-form-row>
    </template>
  </b-form-group>
</template>
<script>
export default {
  name: 'GuestList',
  model: {
    prop: 'guests'
  },
  props: {
    guests: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    validated: { type: Boolean }
  },
  data() {
    return {
      guestList: [],
      newGuest: { Name: undefined, Company: undefined, JobTitle: undefined }
    }
  },
  computed: {
    guestRequired() {
      return !this.guests.length
    },
    state() {
      return !this.validated || !this.guestRequired
    }
  },
  mounted() {
    console.debug('mounted: ', this.guests)
    this.$nextTick().then(() => {
      console.debug('mounted: ', this.guests)
      this.guestList = [...this.guests]

      if (this.guestList.length == 0) {
        this.addGuest()
      }
    })
  },
  methods: {
    addGuest() {
      this.guestList.push(this.newGuest)
      this.$emit('input', this.guestList)
      this.newGuest = {
        Name: undefined,
        Company: undefined,
        JobTitle: undefined
      }
    },
    removeGuest(idx) {
      this.guestList = [
        ...this.guests.slice(0, idx),
        ...this.guests.slice(idx + 1, this.guests.length)
      ]
      this.$emit('input', this.guestList)
    }
  }
}
</script>
<style lang="scss" scoped>
.no-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
