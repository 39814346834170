import axios from 'axios'
import getToken from './adal.js'
import TechnicalSvc from './Technical'
import { NdfRoutes } from './routes'

export default {
  async CreateCategory(cat) {
    const token = await getToken()
    return axios.post(NdfRoutes.NDF_CATEGORIES, cat, {
      headers: { Authorization: token }
    })
  },
  async GetCategories() {
    const token = await getToken()
    return axios.get(NdfRoutes.NDF_CATEGORIES, {
      headers: { Authorization: token }
    })
  },
  async GetCategory(catId) {
    const token = await getToken()
    return axios.get(NdfRoutes.NDF_CATEGORY(catId), {
      headers: { Authorization: token }
    })
  },
  async SetCategory(cat) {
    const token = await getToken()
    return axios.put(NdfRoutes.NDF_CATEGORY(cat.Id), cat, {
      headers: { Authorization: token }
    })
  },
  async DeleteCategory(catId) {
    const token = await getToken()
    return axios.delete(NdfRoutes.NDF_CATEGORY(catId), {
      headers: { Authorization: token }
    })
  },
  async CreateNdf(ndf) {
    const token = await getToken()
    return axios.post(NdfRoutes.MY_NDFS, ndf, {
      headers: { Authorization: token }
    })
  },
  async GetMyNdfs(slice) {
    const token = await getToken()
    return axios.get(NdfRoutes.MY_NDFS, {
      headers: { Authorization: token },
      params: { slice }
    })
  },
  async GetAllNdfs(slice) {
    const token = await getToken()
    return axios.get(process.env.VUE_APP_API_ROOT + '/ndf', {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      },
      params: { slice }
    })
  },
  async GetNdf(ndfId) {
    const token = await getToken()
    return axios.get(NdfRoutes.MY_NDF(ndfId), {
      headers: { Authorization: token }
    })
  },
  async SetNdf(ndf) {
    const token = await getToken()
    return axios.put(NdfRoutes.MY_NDF(ndf.Id), ndf, {
      headers: { Authorization: token }
    })
  },
  async DeleteNdf(ndfId) {
    const token = await getToken()
    return axios.delete(NdfRoutes.MY_NDF(ndfId), {
      headers: { Authorization: token }
    })
  },
  async ValidateNdf(ndfId) {
    const token = await getToken()
    return axios.put(
      NdfRoutes.NDF_VALIDATE(ndfId),
      { Reason: 'OK' },
      {
        headers: { Authorization: token }
      }
    )
  },
  async RejectNdf(ndfId, reason) {
    const token = await getToken()
    return axios.put(
      NdfRoutes.NDF_REJECT(ndfId),
      { Reason: reason },
      {
        headers: { Authorization: token }
      }
    )
  },
  async ChangeNdfCategory(ndfId, catId) {
    const token = await getToken()

    return axios.put(
      NdfRoutes.NDF_CHANGE_CATEGORY(ndfId),
      { CategoryId: catId },
      {
        headers: { Authorization: token }
      }
    )
  },
  async GetNdfDocument(ndfId) {
    const token = await getToken()

    return axios
      .get(NdfRoutes.NDF_DOCUMENT(ndfId), {
        responseType: 'blob',
        headers: { Authorization: token }
      })
      .then(response => ({
        content: response.data,
        contentType: response.headers['content-type']
      }))
      .catch(err => {
        console.error(`Error while fetching NDF [${ndfId}] document: `, err)

        throw err
      })
  },
  async ExportCsv(isClosing = false, force = false) {
    const token = await getToken()
    return axios.get(NdfRoutes.NDF_EXPORT, {
      headers: { Authorization: token },
      params: { isClosing, force }
    })
  },
  async ExportPdf(isClosing = false, force = false) {
    const token = await getToken()
    return axios.get(NdfRoutes.NDF_EXPORT_DOCS, {
      headers: { Authorization: token },
      params: { isClosing, force }
    })
  },
  async MarkNdfAsPayed(isClosing = false) {
    const token = await getToken()
    return axios.get(NdfRoutes.NDF_EXPORT_MARK_AS_PAID, {
      headers: { Authorization: token },
      params: { isClosing }
    })
  }
}
