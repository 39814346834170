<template>
  <b-container>
    <b-row>
      <b-col class="col-2 col-md-4 col-lg-6">
        <b-button variant="primary" @click="onEditClick()">
          <fa-icon icon="plus" />
          <span class="d-none d-md-inline"> Ajouter un client</span>
        </b-button>
        <b-modal
          ref="clientModal"
          title="Client"
          @ok="confirmCreate"
          lazy
          scrollable
          no-close-on-backdrop
        >
          <ClientDetails ref="details" :client="currentClient" />
          <template #modal-footer="{ ok, cancel }">
            <b-button
              v-show="adminMode && !isCreateMode"
              variant="danger"
              class="mr-auto"
              @click="deleteClient"
              ><fa-icon icon="trash-alt" /> Supprimer</b-button
            >
            <b-button variant="secondary" @click="cancel()"
              ><fa-icon icon="ban" /> Annuler</b-button
            >
            <b-button variant="primary" @click="ok()"
              ><fa-icon icon="edit" /> Valider</b-button
            >
          </template>
        </b-modal>
      </b-col>
      <b-col class="text-right col-10 col-md-8 col-lg-6">
        <b-input
          type="search"
          v-model="search"
          placeholder="Nom, SIREN, notes, adresse, réf..."
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="filteredClients"
          :fields="fields"
          :busy="loading"
          class="mt-3"
        >
          <template #table-busy><Loader /></template>
          <template #cell(edit)="row">
            <div class="text-center">
              <span v-b-tooltip.hover title="Editer" class="mr-2">
                <fa-icon
                  icon="edit"
                  @click="onEditClick(row.item)"
                  class="edit text-primary"
                />
              </span>
              <span
                v-b-tooltip.hover
                :title="!row.detailsShowing ? 'Tâches' : 'Masquer'"
              >
                <fa-icon
                  v-if="adminMode"
                  icon="tasks"
                  :class="
                    row.detailsShowing ? 'text-primary' : 'text-secondary'
                  "
                  @click="row.toggleDetails()"
                />
              </span>
            </div>
          </template>
          <template v-if="adminMode" #row-details="{ item }">
            <b-card title="Tâches" bg-variant="light">
              <ClientTasks :clientId="item.Id" />
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { StringMixin } from '@/mixins'
import { mapState, mapActions } from 'vuex'
import { CLIENTS } from '@/store/state-keys'
import { LOAD_CLIENTS, DELETE_CLIENT } from '@/store/action-types'
import { ClientService, TechnicalSvc } from '@/services'
import Loader from '@/components/Loader.vue'
import ClientDetails from '@/components/Timesheet/ClientDetails.vue'
import ClientTasks from '@/components/Timesheet/ClientTasks.vue'
import { MODE } from '@/assets/js/Constants'

export default {
  name: 'Clients',
  components: { Loader, ClientDetails, ClientTasks },
  mixins: [StringMixin],
  data() {
    return {
      currentClient: {},
      mode: '',
      adminMode: false,
      fields: [
        { key: 'Name', label: 'Nom' },
        { key: 'Siren', label: 'SIREN' },
        { key: 'Notes', formatter: v => (v ? v.substr(0, 30) + '...' : '') },
        { key: 'BillingAddress', label: 'Adresse Fact.' },
        //{ key: 'Contacts' },
        { key: 'InsidersContact', label: 'Réf. INSIDERS' },
        { key: 'edit', label: 'Editer' }
      ],
      loading: false,
      search: undefined
    }
  },
  computed: {
    ...mapState([CLIENTS]),
    isCreateMode() {
      return this.mode == MODE.CREATE
    },
    filteredClients() {
      return this[CLIENTS].filter(
        c =>
          !this.search ||
          this.contains(c.Name, this.search) ||
          this.contains(c.Siren, this.search) ||
          this.contains(c.Notes, this.search) ||
          this.contains(c.BillingAddress, this.search) ||
          this.contains(c.InsidersContact, this.search)
      )
    }
  },
  mounted() {
    this.adminMode = TechnicalSvc.GetShowAsAdmin()
    this.loadClients()
  },
  methods: {
    ...mapActions([LOAD_CLIENTS, DELETE_CLIENT]),
    loadClients() {
      this.loading = true

      this[LOAD_CLIENTS]()
        .catch(error => {
          console.error('There was an error:', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onEditClick(client) {
      if (client) {
        this.currentClient = { ...client }
        this.mode = MODE.EDIT
      } else {
        this.currentClient = {}
        this.mode = MODE.CREATE
      }

      this.$refs.clientModal.show()
    },
    confirmCreate(evt) {
      evt.preventDefault()

      if (this.$refs.details.validate()) {
        const prom = this.isCreateMode
          ? ClientService.CreateClient(this.currentClient)
          : ClientService.SetClient(this.currentClient)

        prom.then(() => {
          this.$nextTick(() => this.$refs.clientModal.hide())
          this.loadClients()
        })
      }
    },
    deleteClient() {
      this[DELETE_CLIENT](this.currentClient.Id).then(() => {
        //this.loadClients()
        this.$nextTick(() => {
          this.$refs.clientModal.hide()
          this.currentClient = {}
        })
      })
    }
  }
}
</script>
