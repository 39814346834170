<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-row>
      <b-col>
        <b-form-group label="Nom" horizontal>
          <b-input v-model="client.Name" required />
        </b-form-group>
        <b-form-group label="# SIREN" horizontal>
          <b-input
            v-model="client.Siren"
            pattern="\d+"
            minlength="9"
            maxlength="9"
          />
        </b-form-group>
        <b-form-group label="Adresse de facturation" horizontal>
          <b-textarea
            v-model="client.BillingAddress"
            rows="3"
            max-rows="3"
            required
          />
        </b-form-group>
        <b-form-group label="Adresse e-mail" horizontal>
          <b-input v-model="client.Email" required />
        </b-form-group>
        <b-form-group label="Numéro de téléphone" horizontal>
          <b-input v-model="client.PhoneNumber" required />
        </b-form-group>
        <b-form-group label="Notes" horizontal>
          <b-textarea v-model="client.Notes" rows="3" max-rows="5" />
        </b-form-group>
        <b-form-group label="Contact INSIDERS" horizontal>
          <b-input
            type="email"
            v-model="client.InsidersContact"
            placeholder="contact@insiders.coop"
          />
        </b-form-group>
        <b-form-group v-if="adminMode" label="Identifiant Debitoor" horizontal>
          <b-input v-model="client.DebitoorId" required />
        </b-form-group>
        <b-form-group v-if="adminMode" label="Facturable" label-cols="3">
          <b-checkbox v-model="client.Billable" size="lg" />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { TechnicalSvc } from '@/services'

export default {
  name: 'ClientDetails',
  props: {
    client: { type: Object, required: true }
  },
  data() {
    return {
      validated: false
    }
  },
  computed: {
    adminMode() {
      return TechnicalSvc.GetShowAsAdmin()
    }
  },
  methods: {
    validate() {
      this.validated = true

      return this.$refs.form.checkValidity()
    }
  }
}
</script>
