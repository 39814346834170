import { UserStatus } from '@/assets/js/Constants.js'

const UserMixin = {
  computed: {
    userStatuses() {
      return Object.values(UserStatus)
    }
  },
  methods: {
    formatStatus(status) {
      if (status == UserStatus.New) return 'Nouveau'
      if (status == UserStatus.Modified) return 'Modifié'
      if (status == UserStatus.Rejected) return 'Rejeté'
      if (status == UserStatus.Validated) return 'Validé'
    },
    getStatusVariant(status) {
      if (status == UserStatus.New) return 'secondary'
      if (status == UserStatus.Modified) return 'warning'
      if (status == UserStatus.Rejected) return 'danger'
      if (status == UserStatus.Validated) return 'success'
    },
    isES(user) {
      return (user.Analytique || '').startsWith('ES')
    }
  }
}

export default UserMixin
