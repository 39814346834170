const DateTimePickerConfigMixin = {
  data() {
    return {
      configs: {
        picker: {
          date: {
            format: 'DD/MM/YYYY'
          },
          time: {
            format: 'LT'
          }
        }
      }
    }
  }
}

export default DateTimePickerConfigMixin
