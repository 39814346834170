<template>
  <span class="user-status-filter">
    <b-badge
      v-for="(status, idx) in userStatuses"
      :key="status"
      :variant="
        active.indexOf(status) != -1 ? getStatusVariant(status) : 'default'
      "
      :class="[
        active.indexOf(status) == -1 ? `text-${getStatusVariant(status)}` : '',
        idx > 0 ? 'ml-1' : ''
      ]"
      @click="toggleStatusFilter(status)"
      >{{ formatStatus(status) }}</b-badge
    >
  </span>
</template>
<script>
import { UserStatus } from '@/assets/js/Constants.js'
import { UserMixin } from '@/mixins'

export default {
  name: 'UserStatusFilter',
  mixins: [UserMixin],
  props: {
    value: {
      type: String,
      validator: function (value) {
        return (
          !value ||
          value
            .split(',')
            .map(s => parseInt(s))
            .reduce(
              (p, c) => p && Object.values(UserStatus).indexOf(c) != -1,
              true
            )
        )
      }
    }
  },
  data() {
    return {
      active: []
    }
  },
  watch: {
    value: function (val) {
      this.parseValue(val)
    }
  },
  mounted() {
    this.parseValue(this.value)
  },
  methods: {
    toggleStatusFilter(status) {
      var idx = this.active.indexOf(status)

      if (idx == -1) {
        this.active.push(status)
      } else {
        this.active.splice(idx, 1)
      }

      this.$emit('input', this.active.join(','))
    },
    parseValue(value) {
      !!value ? (this.active = value.split(',').map(s => parseInt(s))) : []
    }
  }
}
</script>
<style lang="scss" scoped>
.user-status-filter {
  .badge {
    cursor: pointer;
  }
}
</style>
