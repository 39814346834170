<template>
  <b-card no-body>
    <template #header>
      <header class="d-flex align-items-center">
        Informations Personnelles
        <validation-switch
          :status="infoValidated"
          class="ml-auto"
          @validate="ValidateInfo()"
          @reject="RejectInfo()"
        />
      </header>
    </template>
    <b-list-group flush>
      <b-list-group-item>
        <dl class="row mb-0">
          <dt :class="dtClass">Nom Complet</dt>
          <dd :class="ddClass">
            {{ userProfile.DisplayName || 'N/A' }}
          </dd>
          <dt :class="dtClass">Prénom</dt>
          <dd :class="ddClass">
            {{ userProfile.FirstName || 'N/A' }}
          </dd>
          <dt :class="dtClass">Nom</dt>
          <dd :class="ddClass" class="mb-0">
            {{ userProfile.LastName || 'N/A' }}
          </dd>
        </dl>
      </b-list-group-item>
      <b-list-group-item>
        <dl class="row mb-0">
          <dt :class="dtClass">Numéro Sécu.</dt>
          <dd :class="ddClass" class="mb-0">
            {{ userProfile.HealthNumber || 'N/A' }}
          </dd>
        </dl>
      </b-list-group-item>
      <b-list-group-item>
        <dl class="row mb-0">
          <dt :class="dtClass">Intitulé du poste</dt>
          <dd :class="ddClass" class="mb-0">
            {{ userProfile.JobTitle || 'N/A' }}
          </dd>
        </dl>
      </b-list-group-item>
      <b-list-group-item>
        <dl class="row mb-0">
          <dt :class="dtClass">Adresse e-mail</dt>
          <dd :class="ddClass">
            {{ userProfile.EmailAddress || 'N/A' }}
          </dd>
          <dt :class="dtClass">Téléphone</dt>
          <dd :class="ddClass" class="mb-0">
            {{ userProfile.PhoneNumber || 'N/A' }}
          </dd>
        </dl>
      </b-list-group-item>
      <b-list-group-item>
        <dl class="row mb-0">
          <dt :class="dtClass">Adresse</dt>
          <dd :class="ddClass" class="mb-0">
            {{ userProfile.Address.StreetName }}<br />
            {{ userProfile.Address.ZipCode }} {{ userProfile.Address.City
            }}<br />
            {{ userProfile.Address.Country }}
          </dd>
        </dl>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import { UserStatus } from '@/assets/js/Constants.js'
import { UserService } from '@/services'
import ValidationSwitch from '../Tool/ValidationSwitch'

export default {
  name: 'PersonalInfoValidationCard',
  components: { ValidationSwitch },
  props: {
    userProfile: { type: Object, required: true }
  },
  data() {
    return {
      dtClass: 'col-5 col-md-12 col-lg-5',
      ddClass: 'col-7 col-md-12 col-lg-7'
    }
  },
  computed: {
    infoValidated() {
      return this.userProfile.Status == UserStatus.Validated
        ? true
        : this.userProfile.Status == UserStatus.Rejected
        ? false
        : undefined
    }
  },
  methods: {
    getLabel(key) {
      switch (key) {
        case 'StreetName':
          return 'Rue'
        case 'ZipCode':
          return 'Code Postal'
        case 'City':
          return 'Ville'
        case 'Country':
          return 'Pays'
      }
    },
    ValidateInfo() {
      UserService.ValidateUserInfo(this.userProfile.Upn)
        .then(() => {
          this.userProfile.Status = UserStatus.Validated
        })
        .catch(this.handleError)
    },
    RejectInfo() {
      UserService.RejectUserInfo(this.userProfile.Upn)
        .then(() => {
          this.userProfile.Status = UserStatus.Rejected
        })
        .catch(this.handleError)
    },
    handleError(err) {
      this.$emit(
        'failed',
        err.response
          ? new Error(err.response.data.Message, { cause: err })
          : err
      )
    }
  }
}
</script>
<style scoped>
.fa-check-circle.validated {
  color: green;
}

.fa-times-circle.rejected {
  color: red;
}
</style>
