<template>
  <b-card :header="boxLabel">
    <Loader v-show="loadingOrSaving" absolute />
    <b-form
      class="card-text"
      novalidate
      :validated="formValidated"
      @submit="ValidateForm"
    >
      <slot><div>Put form content here</div></slot>
      <b-button v-if="!hideSaveButton" type="submit" variant="primary"
        ><fa-icon :icon="['far', 'save']" /> Enregistrer</b-button
      >
    </b-form>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { USER_PROFILE } from '@/store/state-keys'
import {
  SAVE_USER_PROFILE,
  SAVE_USER_PROFILE_PRO
} from '@/store/action-types.js'
import { UserService } from '@/services'
import Loader from '@/components/Loader.vue'

export default {
  name: 'UserInfoBox',
  components: { Loader },
  props: {
    loading: { type: Boolean, default: false },
    boxLabel: {
      type: String,
      default: 'Use <em>boxLabel</em> property to set the box label.'
    },
    hideSaveButton: Boolean,
    profilePro: Boolean
  },
  data() {
    return {
      saving: false,
      formValidated: false
    }
  },
  computed: {
    ...mapState([USER_PROFILE]),
    loadingOrSaving() {
      return this.loading || this.saving
    }
  },
  methods: {
    ...mapActions([SAVE_USER_PROFILE, SAVE_USER_PROFILE_PRO]),
    ValidateForm(e) {
      e.preventDefault()
      e.stopPropagation()

      const form = e.target
      this.formValidated = true

      if (form.checkValidity() === true) {
        this.saving = true

        const prom = !this.profilePro
          ? this[SAVE_USER_PROFILE](this[USER_PROFILE])
          : this[SAVE_USER_PROFILE_PRO](this[USER_PROFILE])

        prom
          .then(() => this.$emit('user-saved'))
          .catch(error => this.$emit('user-not-saved', error))
          .finally(() => {
            this.saving = false
            this.formValidated = false
          })
      }
    },
    SendFile(file) {
      UserService.SaveDocument(file)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  legend {
    text-align: left;
  }

  label {
    text-align: right;
  }

  small {
    text-align: left;
  }
}
</style>
