import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Profile from './views/Profile.vue'
import Validation from './views/Validation.vue'
import TimeSheet from './views/TimeSheet.vue'
import Clients from './views/Clients.vue'
import Projects from './views/Projects.vue'
import Ndf from './views/Ndf.vue'
import NdfCategories from './views/NdfCategories.vue'
//import Dashboard from './views/Dashboard.vue'
import NotFoundComponent from './views/NotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/profil',
      name: 'profil',
      component: Profile,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/validation',
      name: 'validation',
      component: Validation,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/timesheet',
      name: 'timesheet',
      component: TimeSheet,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ndf',
      name: 'ndf',
      component: Ndf,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ndf/categories',
      name: 'ndf-categories',
      component: NdfCategories,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ndf/validation',
      name: 'ndf-validation',
      component: Ndf,
      meta: {
        requireAuth: true
      }
    },
    { path: '*', name: 'not-found', component: NotFoundComponent }
  ]
})
