<template>
  <UserInfoBox
    box-label="Ma photo"
    :hideSaveButton="imageFile == undefined"
    :loading="loading"
    @user-saved="onUserSaved($event)"
    @user-not-saved="onUserNotSaved($event)"
  >
    <b-form-group>
      <div @click="onClick">
        <img
          ref="imgPhoto"
          v-if="imageUrl"
          :src="imageUrl"
          onload="window.URL.revokeObjectURL(this.src)"
        />
        <!-- <img v-else src="../../assets/noimg.png"> -->
        <MissingImageProfile
          v-else
          :firstName="firstName"
          :lastName="lastName"
        />
        <input
          v-show="Boolean(false)"
          ref="photoFile"
          type="file"
          @input="onPhotoInput"
        />
      </div>
    </b-form-group>
  </UserInfoBox>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { USER_PROFILE } from '@/store/state-keys'
import { SET_USER_PHOTO } from '@/store/mutation-types'
import UserInfoBox from '@/components/User/UserInfoBox.vue'
import MissingImageProfile from '@/components/User/MissingImageProfile.vue'
import Utils from '@/assets/js/Utils.js'
import { DataURLMixin } from '@/mixins'

export default {
  name: 'ImageProfileBox',
  components: { UserInfoBox, MissingImageProfile },
  mixins: [DataURLMixin],
  props: {
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      imageFile: undefined
    }
  },
  computed: {
    ...mapState([USER_PROFILE]),
    firstName() {
      return this[USER_PROFILE].FirstName
    },
    lastName() {
      return this[USER_PROFILE].LastName
    },
    imageUrl() {
      if (window.URL && this.imageFile) {
        return window.URL.createObjectURL(this.imageFile)
      } else if (this[USER_PROFILE].Photo.Content) {
        if (!this[USER_PROFILE].Photo.Content.startsWith('data')) {
          return this.makeDataURL(
            this[USER_PROFILE].Photo.ContentType,
            this[USER_PROFILE].Photo.Content
          )
        } else {
          return this[USER_PROFILE].Photo.Content
        }
      }

      return ''
    }
  },
  methods: {
    ...mapMutations([SET_USER_PHOTO]),
    onClick() {
      this.$refs.photoFile.click()
    },
    async onPhotoInput(event) {
      if (event.target.files && event.target.files[0]) {
        this.imageFile = event.target.files[0]
        this[SET_USER_PHOTO](await Utils.fileToDocument(this.imageFile))
      }
    },
    onUserSaved(event) {
      this.imageFile = undefined
      this.$emit('user-saved', event)
    },
    onUserNotSaved(event) {
      this.$emit('user-not-saved', event)
    }
  }
}
</script>
<style scoped>
img,
svg {
  height: 145px;
  width: 145px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
