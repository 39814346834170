import * as Mutations from './mutation-types'
import * as Actions from './action-types'
import { USERS } from './state-keys'
import { UserService, ClientService, ProjectService } from '@/services'

const actions = {
  [Actions.SAVE_USER_PROFILE]({ commit }, profile) {
    return UserService.SetUserProfile(profile)
      .then(response => {
        const retUser = response.data
        const retDocs = retUser.Documents

        commit(Mutations.SET_USER_PHOTO, retUser.Photo)
        commit(Mutations.SET_USER_IDENTITY_CARD, retDocs.Identity)
        commit(
          Mutations.SET_USER_HEALTH_INSURANCE_EVIDENCE,
          retDocs.HealthInsuranceEvidence
        )
        commit(Mutations.SET_USER_CURRICULUM_VITAE, retDocs.CurriculumVitae)
        commit(Mutations.SET_USER_BANK_STATEMENT, retDocs.BankStatement)
      })
      .catch(error => {
        console.error(
          '[STORE] Uncaught error while saving user profile: ',
          error
        )

        throw error
      })
  },
  [Actions.SAVE_USER_PROFILE_PRO](
    _,
    { IsAvailable, ShortBio, Adr, PreferredLocation }
  ) {
    return UserService.SetUserProfilePro({
      IsAvailable,
      ShortBio,
      Adr,
      PreferredLocation
    }).catch(error => {
      console.error(
        '[STORE] Uncaught error while saving user PRO profile: ',
        error
      )

      throw error
    })
  },
  [Actions.SAVE_USER_ADMINISTRATIVE_INFO](
    _,
    { upn, account, tiers, analytique }
  ) {
    return UserService.SetUserAdministrativeInfo(upn, {
      Compte: account,
      tiers,
      analytique
    }).catch(error => {
      console.error(
        '[STORE] Uncaught error while saving user administrative info: ',
        error
      )

      throw error
    })
  },
  [Actions.LOAD_USER_PROFILE]({ commit }, { given_name, family_name, name }) {
    return UserService.GetUserProfile()
      .then(response => {
        const profile = response.data

        if (!profile.FirstName) profile.FirstName = given_name
        if (!profile.LastName) profile.LastName = family_name
        if (!profile.DisplayName) profile.DisplayName = name

        commit(Mutations.SET_USER_PROFILE, profile)
      })
      .catch(error => {
        console.error(
          '[STORE] Uncaught error while loading user profile: ',
          error
        )

        throw error
      })
  },
  [Actions.PUT_MY_SKILL]({ commit }, skill) {
    return UserService.PutMySkill(skill)
      .then(() => {
        commit(Mutations.PUSH_USER_SKILL, skill)
      })
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while adding new user skills: ',
          err
        )

        throw err
      })
  },
  [Actions.DELETE_MY_SKILL]({ commit }, skill) {
    return UserService.DeleteMySkill(skill)
      .then(() => {
        commit(Mutations.POP_USER_SKILL, skill)
      })
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while deleting user skills: ',
          err
        )

        throw err
      })
  },
  [Actions.GET_ALL_SKILLS]({ commit }) {
    return UserService.GetAllSkills()
      .then(response => {
        const skills = response.data

        commit(Mutations.SET_ALL_SKILLS, skills)
      })
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while retrieving all skills: ',
          err
        )

        throw err
      })
  },
  [Actions.LOAD_CLIENTS]({ commit }) {
    return ClientService.GetClients()
      .then(response => commit(Mutations.SET_CLIENTS, response.data))
      .catch(err => {
        console.error('[STORE] Uncaught error while retrieving clients: ', err)

        throw err
      })
  },
  [Actions.DELETE_CLIENT]({ commit }, id) {
    return ClientService.DeleteClient(id)
      .then(() => {
        commit(Mutations.REMOVE_CLIENT, id)
      })
      .catch(err => {
        console.error('[STORE] Uncaught error while deleting client: ', err)

        throw err
      })
  },
  [Actions.LOAD_PROJECTS]({ commit }) {
    return ProjectService.GetProjects()
      .then(response => commit(Mutations.SET_PROJECTS, response.data))
      .catch(err => {
        console.error('[STORE] Uncaught error while retrieving projects: ', err)

        throw err
      })
  },
  [Actions.DELETE_PROJECT]({ commit }, id) {
    return ProjectService.DeleteProject(id)
      .then(() => {
        commit(Mutations.REMOVE_PROJECT, id)
      })
      .catch(err => {
        console.error('[STORE] Uncaught error while deleting project: ', err)

        throw err
      })
  },
  [Actions.LOAD_USERS]({ commit, state }) {
    return UserService.GetUsers()
      .then(response => commit(Mutations.SET_USERS, response.data))
      .then(() =>
        state[USERS].forEach(u => {
          UserService.GetUserPhoto(u.Upn).then(response =>
            commit(Mutations.SET_ES_PHOTO, { upn: u.Upn, photo: response.data })
          )
        })
      )
      .catch(err => {
        console.error('[STORE] Uncaught error while retrieving users: ', err)

        throw err
      })
  },
  [Actions.DELETE_USER]({ commit }, upn) {
    return UserService.DeleteUser(upn)
      .then(() => {
        commit(Mutations.REMOVE_USER, upn)
      })
      .catch(err => {
        console.error('[STORE] Uncaught error while deleting user: ', err)

        throw err
      })
  },
  [Actions.LOAD_NOTIFICATIONS]({ commit }) {
    return UserService.GetNotifications()
      .then(response => commit(Mutations.SET_NOTIFICATIONS, response.data))
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while retrieving notifications: ',
          err
        )

        throw err
      })
  }
}

export default actions
