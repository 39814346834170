import axios from 'axios'
import getToken from './adal.js'
import { EventRoutes } from './routes'

export default {
  async CreateMyEvent(event) {
    const token = await getToken()
    return axios.post(EventRoutes.MY_EVENTS, event, {
      headers: { Authorization: token }
    })
  },
  async GetMyEvents() {
    const token = await getToken()
    return axios.get(EventRoutes.MY_EVENTS, {
      headers: { Authorization: token }
    })
  },
  async GetMyEventsRange(startdate, enddate) {
    const token = await getToken()
    return axios.get(EventRoutes.MY_EVENTS_RANGE(startdate, enddate), {
      headers: { Authorization: token }
    })
  },
  async GetMyEvent(eventId) {
    const token = await getToken()
    return axios.get(EventRoutes.MY_EVENT(eventId), {
      headers: { Authorization: token }
    })
  },
  async SetMyEvent(event) {
    const token = await getToken()
    return axios.put(EventRoutes.MY_EVENT(event.id), event, {
      headers: { Authorization: token }
    })
  },
  async DeleteMyEvent(eventId) {
    const token = await getToken()
    return axios.delete(EventRoutes.MY_EVENT(eventId), {
      headers: { Authorization: token }
    })
  }
}
