<template>
  <b-button-group class="validation-switch">
    <b-button
      :variant="status === true ? 'success' : 'outline-success'"
      size="sm"
      @click="$emit('validate')"
    >
      <fa-icon icon="check-circle" />
    </b-button>
    <b-button
      :variant="status === false ? 'danger' : 'outline-danger'"
      size="sm"
      @click="$emit('reject')"
    >
      <fa-icon icon="times-circle" />
    </b-button>
  </b-button-group>
</template>
<script>
export default {
  name: 'ValidationSwitch',
  props: {
    status: { type: Boolean, default: undefined }
  }
}
</script>
