<template>
  <div>
    <full-calendar
      ref="calendar"
      v-bind="calendarConfig"
      :eventSources="slotsWithHolidays"
      :datesRender="viewRender"
      @eventClick="eventSelected"
      @dateClick="dayClick"
      @select="select"
      @eventResize="eventModify"
      @eventDrop="eventModify"
      :eventAllow="eventAllow"
      :eventRender="eventRender"
      :viewSkeletonRender="render"
      :dayRender="dayRender"
    />
    <b-modal
      ref="eventModal"
      title="Activité"
      @ok="confirmModal"
      @hidden="currentSlot = {}"
      lazy
      no-close-on-backdrop
    >
      <b-alert :show="!!eventModalAlertMessage" variant="danger">
        {{ eventModalAlertMessage }}
      </b-alert>
      <TimeslotDetails
        ref="details"
        :timeslot="currentSlot"
        :isCreateMode="isCreateMode"
      />
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-show="!isCreateMode"
          variant="danger"
          class="mr-auto"
          @click="deleteEvent"
          :disabled="sendingEvent"
          ><fa-icon icon="trash-alt" /> Supprimer</b-button
        >
        <b-button variant="secondary" @click="cancel()" :disabled="sendingEvent"
          ><fa-icon icon="ban" /> Annuler</b-button
        >
        <b-button variant="primary" @click="ok()" :disabled="sendingEvent"
          ><fa-icon icon="edit" /> Valider</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import RemoteIcon from '@/components/Timesheet/RemoteIcon.vue'

const IconClass = Vue.extend(RemoteIcon)

import $ from 'jquery'
import { ProjectMixin } from '@/mixins'
import moment from 'moment'
moment.locale('fr')
import frLocale from '@fullcalendar/core/locales/fr'
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import TimeslotDetails from '@/components/Timesheet/TimeslotDetails.vue'
import { EventService } from '@/services'
import { mapGetters, mapActions } from 'vuex'
import { PROJECT } from '@/store/getter-types.js'
import { LOAD_PROJECTS } from '@/store/action-types.js'
import { MODE } from '@/assets/js/Constants'

export default {
  name: 'Calendar',
  components: { TimeslotDetails, FullCalendar },
  mixins: [ProjectMixin],
  data() {
    return {
      currentSlot: {},
      events: [],
      config: {
        calendar: {
          weekends: true
        }
      },
      mode: MODE.CREATE,
      // loading: false,
      sendingEvent: false,
      yearStart: new Date().getFullYear(),
      yearEnd: new Date().getFullYear(),
      eventModalAlertMessage: undefined,
      calendarView: null
    }
  },
  computed: {
    ...mapGetters([PROJECT]),
    monthStart() {
      return this.calendarView?.currentStart
    },
    monthEnd() {
      return this.calendarView?.currentEnd
    },
    dayCount() {
      return moment.utc(this.monthEnd).diff(moment.utc(this.monthStart), 'days')
    },
    dayRange() {
      return Array(this.dayCount)
        .fill()
        .map((e, i) => moment.utc(this.monthStart).add(i, 'days').toDate())
    },
    weekendDayCount() {
      return this.dayRange.filter(d => d.getDay() == 0 || d.getDay() == 6)
        .length
    },
    publicHolidayCount() {
      const sDate = moment.utc(this.monthStart)
      const eDate = moment.utc(this.monthEnd)

      return this.publicHolidays.filter(e => {
        if (e.start.getDay() == 0 || e.start.getDay() == 6) return false

        const eStart = moment.utc(e.start)

        return eStart.isSameOrAfter(sDate) && eStart.isBefore(eDate)
      }).length
    },
    workingDayCount() {
      return this.dayCount - this.weekendDayCount - this.publicHolidayCount
    },
    slots() {
      return this.events
        .map(s => {
          const p = this.projectsFormatted.find(p => p.value == s.ProjectId)

          const slot = {
            id: s.Id,
            upn: s.Upn,
            allDay: s.AllDay,
            start: moment.utc(s.Start).toDate(),
            end: moment.utc(s.End).toDate(),
            title: p ? p.text : 'Inconnu',
            description: s.Description,
            projectId: s.ProjectId,
            remote: s.Remote
          }

          const duration = s.AllDay
            ? moment.utc(s.End).diff(moment.utc(s.Start), 'days')
            : moment.utc(s.End).diff(moment.utc(s.Start), 'hours', true) / 8
          const realDuration = this.calculateRealDuration(duration, slot)

          return {
            ...slot,
            duration,
            realDuration
          }
        })
        .sort((s1, s2) => s1.start - s2.start)
    },
    remoteDays() {
      const remoteDays = this.dayRange.map(d => ({
        allDay: true,
        groupId: 'remote-work',
        title: 'Télétravail',
        start: d,
        end: null,
        remote: false
      }))

      this.slots
        .filter(s => s.remote)
        .forEach(s => {
          const sDate = moment.utc(s.start)
          const eDate = moment.utc(s.end)

          remoteDays
            .filter(r => {
              const rStart = moment.utc(r.start)

              return (
                (rStart.isSame(sDate, 'day') && rStart.isSame(eDate, 'day')) ||
                rStart.isBetween(sDate, eDate, 'day', '[)')
              )
            })
            .forEach(r => (r.remote = true))
        })

      return remoteDays
    },
    calendarConfig() {
      const rxConfig = this.config.calendar

      return {
        ...rxConfig,
        customButtons: {
          toggleWeekends: {
            text: rxConfig.weekends ? 'W/E Off' : 'W/E On',
            icon: rxConfig.weekends ? 'minus-square' : 'plus-square',
            click: () => (rxConfig.weekends = !rxConfig.weekends)
          }
        },
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay toggleWeekends'
        },
        defaultView: 'dayGridMonth',
        locales: [frLocale],
        locale: 'fr',
        aspectRatio: 2,
        height: 'auto',
        selectable: true,
        selectMirror: true,
        editable: true,
        businessHours: {
          dow: [1, 2, 3, 4, 5],
          start: '00:00',
          end: '23:59:59'
        },
        timeZone: 'UTC',
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin]
      }
    },
    isCreateMode() {
      return this.mode == MODE.CREATE
    },
    slotsWithHolidays() {
      return [
        {
          events: this.slots,
          color: '#033f3c',
          textColor: '#fff'
        },
        {
          events: this.publicHolidays
        },
        {
          events: this.remoteDays
        }
      ]
    },
    publicHolidays() {
      const holidays = this.getPublicHolidays(this.yearStart)

      if (this.yearEnd != this.yearStart) {
        holidays.push(...this.getPublicHolidays(this.yearEnd))
      }

      return holidays.map(e => ({
        ...e,
        groupId: 'public-holidays',
        allDay: true,
        end: null,
        classNames: ['public-holiday'],
        rendering: 'background'
      }))
    }
  },
  methods: {
    ...mapActions([LOAD_PROJECTS]),
    getDate() {
      return this.$refs.calendar.getApi().getDate()
    },
    calculateRealDuration(duration, slot) {
      if (!slot.allDay) {
        return duration
      }

      const date = moment(this.getDate())
      const monthStart = date.clone().startOf('month')
      const monthEnd = monthStart.clone().add(1, 'month')
      const realDuration = Math.round(
        moment
          .min(moment.utc(slot.end), monthEnd)
          .diff(moment.max(moment.utc(slot.start), monthStart), 'days', true)
      )

      return realDuration
    },
    onRowClicked(data) {
      data._showDetails = !data._showDetails
    },
    toggleWeekends() {
      this.config.calendar.showWeekends = !this.config.calendar.showWeekends

      this.$refs.calendar.fireMethod('option', this.calendarConfig)
    },
    calculateEasterMonday(year) {
      const div = (a, b) => (a - (a % b)) / b

      const n = year % 19
      const u = year % 100
      const c = div(year, 100)
      const t = c % 4
      const s = div(c, 4)
      const p = div(c + 8, 25)
      const q = div(c - p + 1, 3)
      const e = (19 * n + c - s - q + 15) % 30
      const d = u % 4
      const b = div(u, 4)
      const L = (2 * t + 2 * b - e - d + 32) % 7
      const h = div(n + 11 * e + 22 * L, 451)
      const j = (e + L - 7 * h + 114) % 31
      const m = div(e + L - 7 * h + 114, 31)

      return new Date(Date.UTC(year, m - 1, j + 2))
    },
    getPublicHolidays(year) {
      const getDay = (day, month) => new Date(Date.UTC(year, month - 1, day))
      const easterMonday = this.calculateEasterMonday(year)
      const easterMoment = moment.utc(easterMonday)
      const ascensionDay = easterMoment.add(38, 'd').toDate()
      const whitMonday = easterMoment.add(11, 'd').toDate()

      return [
        { id: 'new-year', start: getDay(1, 1), title: 'Nouvel An' },
        { id: 'easter-monday', start: easterMonday, title: 'Pâques' },
        { id: 'labor-day', start: getDay(1, 5), title: 'Fête du Travail' },
        { id: 'victory-day', start: getDay(8, 5), title: 'Victoire' },
        { id: 'ascension-day', start: ascensionDay, title: 'Ascension' },
        { id: 'whit-monday', start: whitMonday, title: 'Pentecôte' },
        { id: 'bastille-day', start: getDay(14, 7), title: 'Fête Nationale' },
        { id: 'assumption', start: getDay(15, 8), title: 'Assomption' },
        { id: 'all-saints-day', start: getDay(1, 11), title: 'Toussaint' },
        { id: 'remembrance-day', start: getDay(11, 11), title: 'Armistice' },
        { id: 'christmas-day', start: getDay(25, 12), title: 'Noël' }
      ]
    },
    getWorkingSlot(slot) {
      return {
        ...slot,
        start: moment.utc(slot.start),
        end: moment.utc(slot.end)
      }
    },
    toUtcMoment(date) {
      return moment
        .utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .hours(date.getHours())
        .minutes(date.getMinutes())
        .seconds(0)
        .milliseconds(0)
    },
    render({ view }) {
      this.calendarView = view
    },
    dayRender(info) {
      // console.debug('[dayRender] info:', info)
    },
    eventRender({ event, el }) {
      const {
        rendering,
        groupId,
        title,
        extendedProps: { remote }
      } = event

      if (rendering == 'background') {
        if (groupId == 'public-holidays') {
          $(el)
            .attr('title', title)
            .append(`<div class="fc-event-title ml-4">${title}</div>`)
        }
      } else {
        if (groupId == 'remote-work') {
          const instance = new IconClass({
            propsData: {
              active: remote
            }
          })
          instance.$mount()

          $(el)
            .removeClass('fc-event')
            .attr('style', 'position: absolute; top: 0;')
            .empty()
            .append(instance.$el)
        }
      }
    },
    getDuration: ({ start, end }) =>
      moment.duration(moment.utc(end).diff(moment.utc(start))).asDays(),
    eventAllow(dropInfo, draggedEvent) {
      return (
        this.checkMonthOverlap(dropInfo) &&
        this.checkProjectDuration(dropInfo, draggedEvent)
      )
    },
    checkMonthOverlap(dropInfo) {
      const {
        currentStart: monthStart,
        currentEnd: monthEnd
      } = this.calendarView

      return (
        !(dropInfo.start < monthStart && monthStart < dropInfo.end) &&
        !(dropInfo.start < monthEnd && monthEnd < dropInfo.end)
      )
    },
    checkProjectDuration(dropInfo, draggedEvent) {
      const project = this[PROJECT](draggedEvent.extendedProps.projectId)

      if (!!project) {
        const oldDuration = this.getDuration(draggedEvent)
        const newDuration = this.getDuration(dropInfo)

        if (
          !!project.DayCount &&
          project.DayCount < project.Consumed - oldDuration + newDuration
        ) {
          return false
        }
      }

      return true
    },
    eventSelected(info) {
      const slot = this.slots.find(e => e.id == info.event.id)

      if (slot) {
        this.currentSlot = this.getWorkingSlot(slot)
        this.showModal(MODE.EDIT)
      }
    },
    dayClick(info) {},
    viewRender(info) {
      this.yearStart = info.view.activeStart.getFullYear()
      this.yearEnd = info.view.activeEnd.getFullYear()
      this.loadEvents()
    },
    select(info) {
      this.currentSlot = {
        allDay: info.allDay,
        start: moment.utc(info.start),
        end: moment.utc(info.end)
      }
      this.showModal(MODE.CREATE)
    },
    eventModify(info) {
      const event = info.event
      const slot = this.slots.find(e => e.id == event.id)

      if (slot) {
        const workingSlot = this.getWorkingSlot(slot)

        workingSlot.start = this.toUtcMoment(event.start)
        if (event.end) {
          workingSlot.end = this.toUtcMoment(event.end)
        }

        if (workingSlot.allDay) {
          workingSlot.start = workingSlot.start.startOf('day')
          workingSlot.end = workingSlot.end.startOf('day')
        }

        EventService.SetMyEvent(workingSlot)
          .then(() => {
            // this.replaceEvents(this.slots.map(s => s.id == slot.id ? slot : s))
          })
          .then(() => {
            this.loadEvents()
            this[LOAD_PROJECTS]()
          })
          .catch(err =>
            console.error('Error raised when trying to modify event: ', err)
          )
      }
    },
    confirmModal(evt) {
      evt.preventDefault()
      this.eventModalAlertMessage = undefined

      if (!this.$refs.details.checkSlot()) {
        this.eventModalAlertMessage = this.$refs.details.getErrorMessage()

        return
      }

      this.sendingEvent = true
      const prom = this.isCreateMode
        ? EventService.CreateMyEvent(this.currentSlot).then(() => {
            //this.slots.push(this.currentSlot)
          })
        : EventService.SetMyEvent(this.currentSlot).then(() => {
            // this.replaceEvents(
            //   this.slots.map(
            //     s => (s.id == this.currentSlot.id ? this.currentSlot : s)
            //   )
            // )
          })

      prom
        .then(() => {
          this.loadEvents()
          this.$nextTick(() => {
            this.$refs.eventModal.hide()
          })
          this[LOAD_PROJECTS]()
        })
        .catch(err => {
          this.eventModalAlertMessage = err.message
          console.error('Error while saving event: ', err)
        })
        .finally(() => {
          this.sendingEvent = false
        })
    },
    deleteEvent() {
      this.eventModalAlertMessage = undefined

      this.sendingEvent = true
      EventService.DeleteMyEvent(this.currentSlot.id)
        .then(() => {
          this.removeEvent(this.currentSlot.id)
          this.$nextTick(() => {
            this.$refs.eventModal.hide()
          })
          this[LOAD_PROJECTS]()
        })
        .catch(err => {
          this.eventModalAlertMessage = err.message
          console.error('Error while saving event: ', err)
        })
        .finally(() => {
          this.sendingEvent = false
        })
    },
    // replaceEvents(newEvents) {
    //   this.slots.splice(0, this.slots.length)
    //   Array.prototype.push.apply(this.slots, newEvents)
    // },
    removeEvent(id) {
      const remaining = this.events.filter(e => e.Id != id)
      this.events.splice(0, this.events.length)
      Array.prototype.push.apply(this.events, remaining)
    },
    loadEvents() {
      const date = moment.utc(
        this.$refs.calendar.getApi().getDate().toUTCString()
      )
      const startDate = date.startOf('month').format('YYYY-MM-DD 00:00:00')
      const endDate = date.endOf('month').format('YYYY-MM-DD 23:59:59')

      // this.loading = true
      this.$emit('events-loading')

      EventService.GetMyEventsRange(startDate, endDate)
        .then(response => (this.events = response.data))
        .catch(err => console.error('There was an error: ', err))
        .finally(() => {
          // this.loading = false
          this.$emit('events-loaded', this.slots)
        })
    },
    showModal(mode) {
      this.mode = mode
      this.eventModalAlertMessage = undefined
      this.$refs.eventModal.show()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

#calendar {
  margin-top: 20px;

  ::v-deep .fc-bgevent {
    font-size: 14px;

    &.public-holiday {
      background-color: rgba(24, 176, 185, 0.3);
      opacity: 1;
    }

    &.fc-nonbusiness {
      background-color: rgba(24, 176, 185);
    }

    .fc-event-title {
      margin: 0.5em;
      margin-left: 10px;
      font-size: 0.85em;
      font-style: italic;
      color: #000;
    }
  }
}
</style>
