<template>
  <span class="country-selector" :class="cssClass">
    <img
      v-for="{ zone, code, label } in countries"
      :key="code"
      :src="`/flags/${code}.svg`"
      :title="label"
      :class="{ selected: value == zone }"
      @click="onClick(zone)"
      v-b-tooltip.hover
    />
  </span>
</template>
<script>
import { VatZone } from '@/assets/js/Constants.js'

const SIZES = Object.freeze({
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
})

export default {
  name: 'CountrySelector',
  props: {
    value: { type: [Number], default: () => VatZone.France },
    size: {
      type: String,
      default: SIZES.MD,
      validator: function (value) {
        return (
          !value ||
          Object.entries(SIZES)
            .map(e => e[1])
            .indexOf(value) > -1
        )
      }
    },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      countries: [
        { zone: VatZone.France, code: 'fr', label: 'TVA applicable en France' },
        { zone: VatZone.Europe, code: 'eu', label: 'TVA intra-communautaire' },
        { zone: VatZone.Other, code: 'xx', label: 'TVA hors UE ou erronée' }
      ]
    }
  },
  computed: {
    cssClass() {
      return {
        'country-selector-sm': this.size == SIZES.SM,
        'country-selector-lg': this.size == SIZES.LG,
        disabled: this.disabled
      }
    }
  },
  methods: {
    onClick(zone) {
      if (!this.disabled) {
        this.$emit('input', zone)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.country-selector {
  &:not(.disabled) img {
    cursor: pointer;
  }

  img {
    width: 24px;
    height: 18px;
    object-fit: cover;
    vertical-align: text-bottom;

    &:not(.selected) {
      // filter: grayscale(0.5);
      opacity: 0.5;
    }
  }

  img:not(:first-of-type) {
    margin-left: 5px;
  }

  &.country-selector-sm img {
    width: 16px;
    height: 12px;
  }

  &.country-selector-lg img {
    width: 32px;
    height: 24px;
  }
}
</style>
