<template>
  <b-container>
    <b-row>
      <b-col class="col-2 col-md-4 col-lg-6">
        <b-button variant="primary" @click="onEditClick()">
          <fa-icon icon="plus" />
          <span class="d-none d-md-inline"> Ajouter un projet</span>
        </b-button>
        <b-modal
          ref="projectModal"
          title="Projet"
          @ok="confirmCreate"
          @hidden="currentSlot = undefined"
          lazy
          no-close-on-backdrop
        >
          <ProjectDetails
            ref="details"
            :project="currentProject"
            :projects="projects"
            :clients="clients"
            :users="users"
          />
          <template #modal-footer="{ ok, cancel }">
            <b-button
              v-show="!isCreateMode"
              variant="danger"
              class="mr-auto"
              @click="deleteProject"
              ><fa-icon icon="trash-alt" /> Supprimer</b-button
            >
            <b-button variant="secondary" @click="cancel()"
              ><fa-icon icon="ban" /> Annuler</b-button
            >
            <b-button variant="primary" @click="ok()"
              ><fa-icon icon="edit" /> Valider</b-button
            >
          </template>
        </b-modal>
      </b-col>
      <b-col class="text-right col-10 col-md-8 col-lg-6">
        <b-input
          type="search"
          v-model="search"
          placeholder="Client, nom, date, UPN..."
        />
        <b-form-text>
          <b-form-checkbox v-model="includeFinishedProjects"
            >Afficher les projets terminés</b-form-checkbox
          ></b-form-text
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="filteredProjects"
          :fields="fields"
          :filter="search"
          :filter-included-fields="filterIncluded"
          :busy="loading"
          class="mt-3"
        >
          <template #table-busy><Loader /></template>
          <template #cell(edit)="row">
            <div v-b-tooltip.hover title="Editer" class="text-center">
              <fa-icon
                icon="edit"
                @click="onEditClick(row.item)"
                class="edit text-primary"
              />
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Project } from '@/types'
import { mapState, mapMutations, mapActions } from 'vuex'
import { USERS, CLIENTS } from '@/store/state-keys'
import { SET_PROJECT } from '@/store/mutation-types'
import { LOAD_USERS, LOAD_CLIENTS, DELETE_PROJECT } from '@/store/action-types'
import { StringMixin } from '@/mixins'
import { ProjectService } from '@/services'
import Loader from '@/components/Loader.vue'
import ProjectDetails from '@/components/Timesheet/ProjectDetails.vue'
import { MODE } from '@/assets/js/Constants'

export default {
  name: 'Projects',
  components: { Loader, ProjectDetails },
  mixins: [StringMixin],
  data() {
    return {
      projects: [],
      currentProject: undefined,
      mode: '',
      fields: [
        {
          key: 'ClientId',
          label: 'Client',
          formatter: this.formatClientId,
          filterByFormatted: true,
          sortable: true
        },
        { key: 'Name', label: 'Nom', sortable: true },
        {
          key: 'StartDate',
          label: 'Début',
          formatter: this.formatDate,
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'EndDate',
          label: 'Fin',
          formatter: this.formatDate,
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'Upn',
          sortable: true
        },
        { key: 'edit', label: 'Editer' }
      ],
      filterIncluded: ['ClientId', 'Name', 'StartDate', 'EndDate', 'Upn'],
      loadingStatuses: {
        projects: false,
        clients: false,
        users: false
      },
      search: undefined,
      includeFinishedProjects: false
    }
  },
  computed: {
    ...mapState([USERS, CLIENTS]),
    clients() {
      return this[CLIENTS].map(c => {
        return { value: c.Id, text: c.Name }
      })
    },
    users() {
      return this[USERS].map(u => ({ value: u.Upn, text: u.Upn }))
    },
    isCreateMode() {
      return this.mode == MODE.CREATE
    },
    loading() {
      const { projects, clients, users } = this.loadingStatuses

      return projects && clients && users
    },
    filteredProjects() {
      return this.projects.filter(
        p =>
          this.includeFinishedProjects ||
          !p.EndDate ||
          new Date(p.EndDate) >= new Date()
      )
    }
  },
  mounted() {
    this.loadProjects()
    this.loadClients()
    this.loadUsers()
  },
  methods: {
    ...mapMutations([SET_PROJECT]),
    ...mapActions([LOAD_USERS, LOAD_CLIENTS, DELETE_PROJECT]),
    formatClientId(v) {
      const c = this.clients.find(c => c.value == v)

      return c ? c.text : 'Inconnu'
    },
    formatDate(v) {
      return !!v ? new Date(v).toLocaleDateString('fr-FR') : ''
    },
    loadProjects() {
      this.loadingStatuses.projects = true

      ProjectService.GetProjectsWithoutCP()
        .then(response => {
          this.projects = response.data
        })
        .catch(error => {
          console.error('There was an error:', error)
        })
        .finally(() => {
          this.loadingStatuses.projects = false
        })
    },
    loadClients() {
      this.loadingStatuses.clients = true

      this[LOAD_CLIENTS]()
        .catch(error => {
          console.error('There was an error:', error)
        })
        .finally(() => {
          this.loadingStatuses.clients = false
        })
    },
    loadUsers() {
      this.loadingStatuses.users = true

      this[LOAD_USERS]()
        .catch(error => {
          console.error('There was an error:', error)
        })
        .finally(() => {
          this.loadingStatuses.users = false
        })
    },
    onEditClick(project) {
      if (project) {
        this.currentProject = new Project(project)
        this.mode = MODE.EDIT
      } else {
        this.currentProject = new Project()
        this.mode = MODE.CREATE
      }

      this.$refs.projectModal.show()
    },
    confirmCreate(evt) {
      evt.preventDefault()

      if (this.$refs.details.validate()) {
        const prom = this.isCreateMode
          ? ProjectService.CreateProject(this.currentProject)
          : ProjectService.SetProject(this.currentProject)

        prom
          .then(() => {
            this[SET_PROJECT](this.currentProject)
            this.loadProjects()
            this.$nextTick(function () {
              this.$refs.projectModal.hide()
            })
          })
          .catch(err => console.error('There was an error: ', err))
      }
    },
    deleteProject(evt) {
      evt.preventDefault()

      this[DELETE_PROJECT](this.currentProject.Id)
        .then(() => {
          this.loadProjects()
          this.$nextTick(() => {
            this.$refs.projectModal.hide()
          })
        })
        .catch(err => {
          console.error('There was an error: ', err)
        })
    }
  }
}
</script>
