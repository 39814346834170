<template>
  <b-navbar toggleable="md" sticky id="mainNav">
    <div class="container">
      <b-navbar-brand :to="{ name: 'home' }">
        <img
          src="../assets/SigneSeul.jpg"
          class="d-none d-md-block d-lg-none"
        />
        <img src="../assets/logo.png" class="d-md-none d-lg-block" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse" />
      <b-collapse id="nav_collapse" is-nav>
        <b-navbar-nav>
          <b-nav-form>
            <b-form-checkbox
              v-model="adminMode"
              @change="updateAdminMode"
              switch
              v-if="isAdmin()"
              ><b-form-text>Mode Admin</b-form-text></b-form-checkbox
            >
          </b-nav-form>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            v-if="isAdmin() || isSalariedEntrepreneur()"
            :to="{ name: 'ndf' }"
            :active="$route.name == 'ndf'"
          >
            <NavBarItem
              icon="euro-sign"
              label="Mes frais"
              :badge-label="notifications.NdfRejected"
              badge-variant="danger"
              responsive
            />
          </b-nav-item>
          <b-nav-item
            v-if="isSalariedEntrepreneur() || isAdmin()"
            :to="{ name: 'timesheet' }"
            :active="$route.name == 'timesheet'"
          >
            <NavBarItem label="Activité" responsive>
              <template #layers>
                <fa-icon icon="calendar" />
                <fa-layers-text
                  class="fa-inverse"
                  transform="shrink-8 down-3"
                  :value="new Date().getDate()"
                  style="font-weight: 900"
                />
              </template>
            </NavBarItem>
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="isSalariedEntrepreneur() || isAdmin()"
            :class="{ active: missionsActive }"
          >
            <template #button-content>Missions</template>
            <b-dropdown-item
              :to="{ name: 'clients' }"
              :active="$route.name == 'clients'"
            >
              <NavBarItem icon="user-tie" label="Clients" />
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'projects' }"
              :active="$route.name == 'projects'"
            >
              <NavBarItem icon="laptop-code" label="Projets" />
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-if="isAdmin()"
            :class="{ active: adminActive }"
            right
          >
            <template #button-content
              >Admin
              <b-badge v-if="notifications.UserValidations" variant="danger">{{
                notifications.UserValidations
              }}</b-badge></template
            >
            <b-dropdown-group header="Notes de frais">
              <b-dropdown-item
                :to="{ name: 'ndf-validation' }"
                :active="$route.name == 'ndf-validation'"
              >
                <NavBarItem
                  icon="file-export"
                  label="Validation / Export"
                  :badge-label="notifications.NdfValidations"
                  :badge-variant="
                    $route.name == 'ndf-validation' ? 'light' : 'info'
                  "
                />
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'ndf-categories' }"
                :active="$route.name == 'ndf-categories'"
              >
                <NavBarItem icon="th-large" label="Catégories" />
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group header="Salarié·es">
              <b-dropdown-item
                :to="{ name: 'validation' }"
                :active="$route.name == 'validation'"
              >
                <NavBarItem
                  icon="user-check"
                  label="Validation des profils"
                  :badge-label="notifications.UserValidations"
                  :badge-variant="
                    $route.name == 'validation' ? 'light' : 'info'
                  "
                />
              </b-dropdown-item>
            </b-dropdown-group>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown :class="{ active: profileActive }" right>
            <template #button-content>
              <em>{{ user.name }}</em>
              <b-avatar size="30" class="ml-2" />
            </template>
            <b-dropdown-item
              :to="{ name: 'profil' }"
              :active="$route.name == 'profil'"
            >
              <NavBarItem icon="user" label="Profil" />
            </b-dropdown-item>
            <b-dropdown-item @click="$adal.logout()">
              <NavBarItem icon="power-off" label="Me déconnecter" />
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import { AdalMixin } from '@/mixins'
import { TechnicalSvc } from '@/services'
import { mapState } from 'vuex'
import { NOTIFICATIONS } from '@/store/state-keys'
import NavBarItem from './NavBarItem.vue'

let user = { name: '' }

export default {
  name: 'NavBar',
  components: { NavBarItem },
  mixins: [AdalMixin],
  data() {
    return {
      user: user,
      adminMode: false
    }
  },
  computed: {
    ...mapState({ notifications: NOTIFICATIONS }),
    missionsActive() {
      return this.$route.name == 'clients' || this.$route.name == 'projects'
    },
    adminActive() {
      return ['validation', 'ndf-categories', 'ndf-validation'].indexOf(
        this.$route.name
      )
    },
    profileActive() {
      return this.$route.name == 'profil'
    }
  },
  mounted() {
    user.name = this.$adal.user.profile.name
    this.adminMode = TechnicalSvc.GetShowAsAdmin() ? true : false
  },
  methods: {
    updateAdminMode(value) {
      TechnicalSvc.SetShowAsAdmin(value)
      location.reload()
    }
  }
}
</script>

<style scoped>
#mainNav {
  background-color: white;
  padding-bottom: 2px;
}
#navbar {
  background-color: white;
}
.navbar-brand img {
  height: 30px;
}
#nav {
  vertical-align: bottom;
}
#nav .menu {
  text-align: right;
}

#video a {
  font-weight: bold;
  color: #033f3c;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav a {
  color: #033f3c;
}

#mainNav a.router-link-exact-active,
#mainNav .dropdown.active /deep/ a.dropdown-toggle {
  color: #18b0b9;
}

#mainNav /deep/ a.dropdown-item.router-link-exact-active {
  background-color: #18b0b9;
}

.row {
  height: 30px;
}
#contactus a {
  color: white;
}
#contactus {
  margin: 0px;
  padding: 0px;
  background-color: #033f3c;
}
a:hover {
  opacity: 0.8;
}
</style>
