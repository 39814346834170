export const getToken = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('adal.idtoken') != null) {
      resolve('Bearer ' + localStorage.getItem('adal.idtoken'))
    } else {
      reject('Empty token')
    }
  })
}

export default getToken
