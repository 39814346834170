<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <g opacity=".8" fill="#18b1b9">
      <path
        d="M121 0C53.9-.6-.6 53.9 0 121c.6 65.2 53.8 118.4 119 119 67.1.6 121.6-53.9 121-121C239.4 53.8 186.2.6 121 0zM90.5 59c.3-.9 1-1.5 2.2-1.2 2.2.5 19.9 4.3 19.9 4.3s36.8-5.6 38.1-5.9c1.1-.2 1.9.4 2.1 1.4.1.4 6.3 21.3 11.7 39.5H78.3C83.9 79.6 90.1 60 90.5 59zm85.9 103.3c-.8 12.2-10.7 22.1-22.9 22.9-14.3.9-26.1-10.4-26.1-24.5 0-.7 0-1.4.1-2.1-2-.7-4.2-1-6.4-1-2.3 0-4.5.4-6.7 1.1.1.7.1 1.3.1 2 0 14.1-11.8 25.4-26.1 24.5-12.2-.8-22.1-10.7-22.9-22.9-.8-14.2 10.5-26.1 24.5-26.1 10.2 0 19 6.3 22.7 15.2 2.7-.8 5.5-1.3 8.4-1.3 2.8 0 5.5.4 8.1 1.2 3.7-8.9 12.4-15.1 22.7-15.1 14.1 0 25.4 11.9 24.5 26.1zm23.4-34.3H42.4c-.2 0-.3-.3-.1-.4 5.2-2.7 35.4-17.6 79-17.6 43.7 0 73.5 14.8 78.6 17.6.2.1.1.4-.1.4z"
      />
      <circle cx="151.9" cy="160.8" r="17.4" />
      <circle cx="90.1" cy="160.8" r="17.4" />
      <text x="50%" y="90%" text-anchor="middle" fill="white" textLength="50%">
        {{ shortName }}
      </text>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    firstName: String,
    lastName: String
  },
  computed: {
    shortName() {
      return this.firstName && this.lastName
        ? this.firstName + ' ' + this.lastName[0] + '.'
        : ''
    }
  }
}
</script>
