const DateMixin = {
  methods: {
    parseDate(dateStr) {
      const [date, month, year] = dateStr.split('/')

      return new Date(year, month - 1, date)
    }
  }
}

export default DateMixin
