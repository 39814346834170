const NDF_ROOT = `${process.env.VUE_APP_API_ROOT}/ndf`
const NDF_CATEGORIES = `${NDF_ROOT}/categories`
const NDF_CATEGORY = catId => `${NDF_CATEGORIES}/${catId}`

const MY_NDFS = `${process.env.VUE_APP_API_ROOT}/me/ndf`
const MY_NDF = ndfId => `${MY_NDFS}/${ndfId}`

const NDF = ndfId => `${NDF_ROOT}/${ndfId}`
const NDF_VALIDATE = ndfId => `${NDF(ndfId)}/validate`
const NDF_REJECT = ndfId => `${NDF(ndfId)}/reject`
const NDF_CHANGE_CATEGORY = ndfId => `${NDF(ndfId)}/change-category`

const NDF_DOCUMENT = ndfId => `${NDF(ndfId)}/document`

const NDF_EXPORT = `${process.env.VUE_APP_API_ROOT}/export/ndf`
const NDF_EXPORT_DOCS = `${NDF_EXPORT}/docs`
const NDF_EXPORT_MARK_AS_PAID = `${NDF_EXPORT}/mark-as-paid`

export {
  NDF_CATEGORIES,
  NDF_CATEGORY,
  MY_NDFS,
  MY_NDF,
  NDF_VALIDATE,
  NDF_REJECT,
  NDF_CHANGE_CATEGORY,
  NDF_DOCUMENT,
  NDF_EXPORT,
  NDF_EXPORT_DOCS,
  NDF_EXPORT_MARK_AS_PAID
}
