<template>
  <b-container id="not-found">
    <b-row>
      <b-col>
        <h1>Moo?!</h1>
        <img src="../assets/noimg.png" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>

<style>
#not-found {
  text-align: center;
}
</style>
