export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_USER_FIRST_NAME = 'SET_USER_FIRST_NAME'
export const SET_USER_LAST_NAME = 'SET_USER_LAST_NAME'
export const SET_USER_HEALTH_NUMBER = 'SET_USER_HEALTH_NUMBER'
export const SET_USER_JOB_TITLE = 'SET_USER_JOB_TITLE'
export const SET_USER_EMAIL_ADDRESS = 'SET_USER_EMAIL_ADDRESS'
export const SET_USER_PHONE_NUMBER = 'SET_USER_PHONE_NUMBER'
export const SET_USER_STREET_NAME = 'SET_USER_STREET_NAME'
export const SET_USER_ZIP_CODE = 'SET_USER_ZIP_CODE'
export const SET_USER_CITY = 'SET_USER_CITY'
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY'
export const SET_USER_IS_AVAILABLE = 'SET_USER_IS_AVAILABLE'
export const SET_USER_SHORT_BIO = 'SET_USER_SHORT_BIO'
export const SET_USER_SKILLS = 'SET_USER_SKILLS'
export const SET_USER_ADR = 'SET_USER_ADR'
export const SET_USER_PREFERRED_LOCATION = 'SET_USER_PREFERRED_LOCATION'
export const PUSH_USER_SKILL = 'PUSH_USER_SKILL'
export const POP_USER_SKILL = 'POP_USER_SKILL'
export const SET_USER_PHOTO = 'SET_USER_PHOTO'
export const SET_USER_IDENTITY_CARD = 'SET_USER_IDENTITY_CARD'
export const SET_USER_HEALTH_INSURANCE_EVIDENCE =
  'SET_USER_HEALTH_INSURANCE_EVIDENCE'
export const SET_USER_CURRICULUM_VITAE = 'SET_USER_CURRICULUM_VITAE'
export const SET_USER_BANK_STATEMENT = 'SET_USER_BANK_STATEMENT'
export const SET_ALL_SKILLS = 'SET_ALL_SKILLS'
export const SET_CLIENTS = 'SET_CLIENTS'
export const REMOVE_CLIENT = 'REMOVE_CLIENT'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECT = 'SET_PROJECT'
export const REMOVE_PROJECT = 'REMOVE_PROJECT'
export const SET_USERS = 'SET_USERS'
export const REMOVE_USER = 'REMOVE_USER'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_ES_PHOTO = 'SET_ES_PHOTO'
