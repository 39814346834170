<template>
  <UserInfoBox
    box-label="Identité"
    :loading="loading"
    @user-saved="onUserSaved($event)"
    @user-not-saved="onUserNotSaved($event)"
  >
    <b-form-group
      id="displayNameGroup"
      label="Nom Complet"
      label-for="displayName"
      label-cols-sm="4"
      label-cols-lg="3"
    >
      <b-form-input
        id="displayName"
        type="text"
        readonly
        :value="displayName"
      />
    </b-form-group>
    <b-form-group
      id="firstNameGroup"
      label="Prénom"
      label-for="firstName"
      label-cols-sm="4"
      label-cols-lg="3"
      :class="{ 'table-warning': !firstName }"
    >
      <b-form-input id="firstName" type="text" v-model="firstName" required />
    </b-form-group>
    <b-form-group
      id="lastNameGroup"
      label="Nom"
      label-for="lastname"
      label-cols-sm="4"
      label-cols-lg="3"
      :class="{ 'table-warning': !lastName }"
    >
      <b-form-input id="lastName" type="text" v-model="lastName" required />
    </b-form-group>
    <b-form-group
      id="healthNumberGroup"
      label="Numéro de sécu."
      label-for="healthNumber"
      label-cols-sm="4"
      label-cols-lg="3"
      :class="{ 'table-warning': !healthNumber }"
    >
      <b-form-input
        id="healthNumber"
        type="text"
        v-model="healthNumber"
        required
        pattern="\d{13}"
      />
    </b-form-group>
  </UserInfoBox>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { USER_PROFILE } from '@/store/state-keys'
import {
  SET_USER_FIRST_NAME,
  SET_USER_LAST_NAME,
  SET_USER_HEALTH_NUMBER
} from '@/store/mutation-types'
import UserInfoBox from '@/components/User/UserInfoBox.vue'

export default {
  name: 'IdentityBox',
  components: { UserInfoBox },
  props: {
    loading: { type: Boolean, default: false }
  },
  computed: {
    ...mapState([USER_PROFILE]),
    // TODO Remove side effect.
    displayName() {
      return this[USER_PROFILE].FirstName || this[USER_PROFILE].LastName
        ? `${this[USER_PROFILE].FirstName} ${this[
            USER_PROFILE
          ].LastName.toUpperCase()}`
        : this.$adal.user.profile.name
    },
    firstName: {
      get() {
        return this[USER_PROFILE].FirstName
      },
      set(value) {
        this[SET_USER_FIRST_NAME](value)
      }
    },
    lastName: {
      get() {
        return this[USER_PROFILE].LastName
      },
      set(value) {
        this[SET_USER_LAST_NAME](value)
      }
    },
    healthNumber: {
      get() {
        return this[USER_PROFILE].HealthNumber
      },
      set(value) {
        this[SET_USER_HEALTH_NUMBER](value)
      }
    }
  },
  methods: {
    ...mapMutations([
      SET_USER_FIRST_NAME,
      SET_USER_LAST_NAME,
      SET_USER_HEALTH_NUMBER
    ]),
    onUserSaved(event) {
      this.$emit('user-saved', event)
    },
    onUserNotSaved(event) {
      this.$emit('user-not-saved', event)
    }
  }
}
</script>
