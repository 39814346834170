<template>
  <b-container>
    <b-row>
      <b-col class="col-2 col-md-4 col-lg-6">
        <b-button variant="primary" @click="onEditClick()">
          <fa-icon icon="plus" />
          <span class="d-none d-md-inline"> Ajouter une catégorie</span>
        </b-button>
        <b-modal ref="catModal" title="Catégorie" @ok="confirmCreate" lazy>
          <CategoryDetails ref="details" :category="currentCategory" />
          <template #modal-footer="{ ok, cancel }">
            <b-button
              v-show="!isCreateMode"
              variant="danger"
              class="mr-auto"
              @click="deleteCategory"
              ><fa-icon icon="trash-alt" /> Supprimer</b-button
            >
            <b-button variant="secondary" @click="cancel()"
              ><fa-icon icon="ban" /> Annuler</b-button
            >
            <b-button variant="primary" @click="ok()"
              ><fa-icon icon="edit" /> Valider</b-button
            >
          </template>
        </b-modal>
      </b-col>
      <b-col class="text-right col-10 col-md-8 col-lg-6">
        <b-input
          type="search"
          v-model="search"
          placeholder="Code, nom, descripiton..."
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="filteredCategories"
          :fields="fields"
          :busy="loading"
          class="mt-3"
          sort-icon-left
        >
          <template #table-busy><Loader /></template>
          <template #cell(edit)="row">
            <div v-b-tooltip.hover title="Editer" class="text-center">
              <fa-icon
                icon="edit"
                @click="onEditClick(row.item)"
                class="edit text-primary"
              />
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { StringMixin } from '@/mixins'
import { NdfService } from '@/services'
import Loader from '@/components/Loader.vue'
import CategoryDetails from '@/components/Ndf/CategoryDetails.vue'
import { MODE } from '@/assets/js/Constants'

export default {
  name: 'NdfCategories',
  components: { Loader, CategoryDetails },
  mixins: [StringMixin],
  data() {
    return {
      categories: [],
      currentCategory: {},
      mode: '',
      fields: [
        { key: 'Code', label: 'Code', sortable: true },
        { key: 'Name', label: 'Nom', sortable: true },
        { key: 'Description', label: 'Description' },
        { key: 'VatAccount', label: 'Compte TVA', sortable: true },
        {
          key: 'VatRatio',
          label: 'Part TVA',
          sortable: true,
          formatter: (v, k, i) => (i.VatAccount ? `${v * 100}%` : '')
        },
        { key: 'edit', label: 'Editer' }
      ],
      loading: false,
      search: undefined
    }
  },
  computed: {
    isCreateMode() {
      return this.mode == MODE.CREATE
    },
    filteredCategories() {
      return this.categories.filter(
        c =>
          !this.search ||
          this.contains(c.Code, this.search) ||
          this.contains(c.Name, this.search) ||
          this.contains(c.Description, this.search)
      )
    }
  },
  mounted() {
    this.loadCategories()
  },
  methods: {
    loadCategories() {
      this.loading = true

      NdfService.GetCategories()
        .then(response => {
          this.categories = response.data
        })
        .catch(error => {
          console.error('There was an error:', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onEditClick(category) {
      if (category) {
        this.currentCategory = { ...category }
        this.mode = MODE.EDIT
      } else {
        this.currentCategory = {}
        this.mode = MODE.CREATE
      }

      this.$refs.catModal.show()
    },
    confirmCreate(evt) {
      evt.preventDefault()

      if (this.$refs.details.validate()) {
        const prom = this.isCreateMode
          ? NdfService.CreateCategory(this.currentCategory)
          : NdfService.SetCategory(this.currentCategory)

        prom.then(() => {
          this.$nextTick(() => {
            this.$refs.catModal.hide()
          })
          this.loadCategories()
        })
      }
    },
    deleteCategory() {
      NdfService.DeleteCategory(this.currentCategory.Id).then(() => {
        this.loadCategories()
        this.$refs.catModal.hide()
        this.currentCategory = {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
svg.edit {
  cursor: pointer;
}
</style>
