<template>
  <b-card body-class="text-center">
    <template #header>
      <header class="d-flex align-items-center">
        Photo
        <validation-switch
          :status="photoValidated"
          class="ml-auto"
          @validate="ValidatePhoto()"
          @reject="RejectPhoto()"
        />
      </header>
    </template>
    <b-avatar :src="photoUrl" size="150" />
  </b-card>
</template>
<script>
import { DocumentStatus } from '@/assets/js/Constants.js'
import { UserService } from '@/services'
import { DataURLMixin } from '@/mixins'
import ValidationSwitch from '../Tool/ValidationSwitch'

export default {
  name: 'PhotoValidationCard',
  components: { ValidationSwitch },
  mixins: [DataURLMixin],
  props: {
    userProfile: { type: Object, required: true }
  },
  computed: {
    photoValidated() {
      return this.userProfile.Photo.Status == DocumentStatus.Validated
        ? true
        : this.userProfile.Photo.Status == DocumentStatus.Rejected
        ? false
        : undefined
    },
    photoUrl() {
      return this.makeDataURL(
        this.userProfile.Photo.ContentType,
        this.userProfile.Photo.Content
      )
    }
  },
  methods: {
    ValidatePhoto() {
      UserService.ValidateUserPhoto(this.userProfile.Upn)
        .then(() => {
          this.userProfile.Photo.Status = DocumentStatus.Validated
        })
        .catch(this.handleError)
    },
    RejectPhoto() {
      UserService.RejectUserPhoto(this.userProfile.Upn)
        .then(() => {
          this.userProfile.Photo.Status = DocumentStatus.Rejected
        })
        .catch(this.handleError)
    },
    handleError(err) {
      this.$emit(
        'failed',
        err.response
          ? new Error(err.response.data.Message, { cause: err })
          : err
      )
    }
  }
}
</script>
<style scoped>
img.photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.fa-check-circle.validated {
  color: green;
}

.fa-times-circle.rejected {
  color: red;
}
</style>
