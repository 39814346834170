<template>
  <div class="nav-bar-item d-flex align-items-center w-100">
    <span v-if="icon || hasLayers" class="mr-1" :class="iconClass">
      <fa-icon v-if="!hasLayers" :icon="icon" fixed-width />
      <fa-layers v-else fixed-width>
        <slot name="layers"></slot>
      </fa-layers>
    </span>
    <span
      v-if="responsive && (icon || hasLayers)"
      v-b-tooltip:hover
      :title="label"
      class="fa-lg d-none d-md-inline d-lg-none"
    >
      <fa-icon v-if="!badgeLabel && !hasLayers" :icon="icon" />
      <fa-layers v-else>
        <fa-icon v-if="!hasLayers" :icon="icon" />
        <slot v-else name="layers"></slot>
        <fa-layers-text v-if="badgeLabel" :value="badgeLabel" counter />
      </fa-layers>
    </span>
    <span class="mr-auto" :class="labelClass">{{ label }}</span>
    <b-badge
      v-if="badgeLabel"
      :variant="badgeVariant"
      class="ml-2"
      :class="badgeClass"
      >{{ badgeLabel }}</b-badge
    >
  </div>
</template>
<script>
export default {
  name: 'NavBarItem',
  props: {
    icon: { type: String },
    label: { type: String, required: true },
    badgeLabel: { type: [String, Number] },
    badgeVariant: { type: String },
    responsive: { type: Boolean, default: false }
  },
  computed: {
    hasLayers() {
      return !!this.$slots.layers
    },
    iconClass() {
      return this.responsive ? 'd-inline d-md-none d-lg-inline' : ''
    },
    labelClass() {
      return this.responsive ? 'd-inline d-md-none d-lg-inline' : ''
    },
    badgeClass() {
      return this.responsive ? 'd-inline d-md-none d-lg-inline' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-bar-item {
  .fa-layers-counter {
    transform: scale(0.5) translateX(25%);
  }
}
</style>
