export const fillDocument = (document, source) => {
  document.FileName = source.FileName
  document.Size = source.Size
  document.Content = source.Content
  document.Status = source.Status
}

const GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export const isEmpty = guid => {
  return guid == GUID_EMPTY
}

export const compareProjects = (p1, p2) => {
  if (isEmpty(p1.ClientId) && !isEmpty(p2.ClientId)) return -1
  if (!isEmpty(p1.ClientId) && isEmpty(p2.ClientId)) return 1

  if (isEmpty(p1.ClientId) && isEmpty(p2.ClientId)) {
    if (p1.Name[0] == '-' && p2.Name[0] != '-') return -1
    if (p1.Name[0] != '-' && p2.Name[0] == '-') return 1
  }

  return (p1.Name || '').localeCompare(p2.Name || '')
}
