<template>
  <b-form-group
    v-bind="{ id, label, labelFor }"
    label-cols="6"
    label-cols-lg="5"
    label-cols-xl="6"
    label-align="right"
    variant="warning"
  >
    <b-input
      :id="labelFor"
      :value="value"
      @update="$emit('input', $event)"
      :state="state"
      :required="required"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'AdministrativeInfoCardItem',
  props: {
    id: String,
    label: String,
    labelFor: String,
    value: String,
    dirty: Boolean,
    required: Boolean
  },
  computed: {
    state() {
      if (this.value == '') return false
      else return this.dirty || undefined
    }
  }
}
</script>
