const VatMixin = {
  methods: {
    getInclAmount(items) {
      return items.reduce(
        (p, c) =>
          p +
          (c.Included
            ? Number(c.Amount)
            : Number(c.Amount) * (1 + Number(c.Rate))),
        0
      )
    },
    getVatAmount(items) {
      return items.reduce(
        (p, c) =>
          p +
          (c.Included
            ? (Number(c.Rate) * Number(c.Amount)) / (1 + Number(c.Rate))
            : Number(c.Amount * c.Rate)),
        0
      )
    },
    getExclAmount(items) {
      return items.reduce(
        (p, c) =>
          p +
          (c.Included
            ? Number(c.Amount) / (1 + Number(c.Rate))
            : Number(c.Amount)),
        0
      )
    },
    formatAmount(amount) {
      return amount.toFixed(2).replace('.00', '')
    }
  }
}

export default VatMixin
