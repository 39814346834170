<template>
  <b-media>
    <template #aside>
      <b-avatar
        :src="photoUrl"
        size="50"
        :badge-variant="user.IsHiddenOnWebsite ? 'warning' : 'success'"
        badge-offset="-5px"
      >
        <template #badge v-if="isES(user)">
          <span
            v-b-tooltip.hover
            :title="`${
              user.IsHiddenOnWebsite ? 'Invisible' : 'Visible'
            } sur Insiders.coop`"
            class="btn-icon"
          >
            <fa-icon
              :icon="['far', user.IsHiddenOnWebsite ? 'eye-slash' : 'eye']"
              @click="onHideClick(user)"
            />
          </span>
        </template>
      </b-avatar>
    </template>
    {{ user.DisplayName }}
    <small class="d-block text-muted">{{ user.JobTitle }}</small>
  </b-media>
</template>
<script>
import { DataURLMixin, UserMixin } from '@/mixins'

export default {
  name: 'user-card',
  props: {
    user: { type: Object, required: true }
  },
  mixins: [DataURLMixin, UserMixin],
  computed: {
    photoUrl() {
      return this.makeDataURL(
        this.user.Photo.ContentType,
        this.user.Photo.Content
      )
    }
  }
}
</script>
