import * as State from './state-keys'
import * as Getters from './getter-types'
import { compareProjects, isEmpty } from './utils'

const getters = {
  [Getters.MAX_SCORE]: () => 11, // 15
  [Getters.SCORE]: state => {
    const profile = state[State.USER_PROFILE]

    return (
      (profile.FirstName ? 1 : 0) +
      (profile.LastName ? 1 : 0) +
      (profile.HealthNumber ? 1 : 0) +
      (profile.JobTitle ? 1 : 0) +
      (profile.EmailAddress ? 1 : 0) +
      (profile.PhoneNumber ? 1 : 0) +
      (profile.Address.StreetName ? 1 : 0) +
      (profile.Address.ZipCode ? 1 : 0) +
      (profile.Address.City ? 1 : 0) +
      (profile.Address.Country ? 1 : 0) +
      (profile.Photo.Content ? 1 : 0)
      // +
      // Object.values(profile.Documents).reduce((total, value) => {
      //   return total + (value.Status ? 1 : 0)
      // }, 0)
    )
  },
  [Getters.COMPLETION_RATE]: (state, getters) => {
    return (
      (100 * getters[Getters.SCORE]) /
      getters[Getters.MAX_SCORE]
    ).toFixed(0)
  },
  [Getters.CLIENT]: state => id => state[State.CLIENTS].find(c => c.Id == id),
  [Getters.CLIENT_NAME]: (_, getters) => id =>
    !isEmpty(id) ? getters[Getters.CLIENT](id)?.Name : '',
  [Getters.PROJECTS]: state =>
    state[State.PROJECTS]
      .filter(p => !!p.Name && !!p.Name.length)
      .sort(compareProjects),
  [Getters.PROJECT]: state => id => state[State.PROJECTS].find(p => p.Id == id),
  [Getters.USER]: state => upn => state[State.USERS].find(u => u.Upn == upn),
  [Getters.INTERNAL_PROJECTS]: (_, getters) =>
    getters[Getters.PROJECTS]
      .filter(p => isEmpty(p.ClientId))
      .sort(compareProjects)
}

export default getters
