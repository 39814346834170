import axios from 'axios'
import getToken from './adal.js'
import { ClientRoutes } from './routes'

export default {
  async CreateClient(client) {
    const token = await getToken()
    return axios.post(ClientRoutes.CLIENTS, client, {
      headers: { Authorization: token }
    })
  },
  async GetClients() {
    const token = await getToken()
    return axios.get(ClientRoutes.CLIENTS, {
      headers: { Authorization: token }
    })
  },
  async SetClient(client) {
    const token = await getToken()
    return axios.put(ClientRoutes.CLIENT(client.id), client, {
      headers: { Authorization: token }
    })
  },
  async DeleteClient(clientId) {
    const token = await getToken()
    return axios.delete(ClientRoutes.CLIENT(clientId), {
      headers: { Authorization: token }
    })
  },
  async CreateClientTask(clientId, task) {
    const token = await getToken()
    return axios.post(ClientRoutes.CLIENT_TASKS(clientId), task, {
      headers: { Authorization: token }
    })
  },
  async GetClientTasks(clientId) {
    const token = await getToken()
    return axios.get(
      process.env.VUE_APP_API_ROOT + `/clients/${clientId}/tasks`,
      {
        headers: { Authorization: token }
      }
    )
  },
  async SetClientTask(clientId, task) {
    const token = await getToken()
    return axios.put(
      process.env.VUE_APP_API_ROOT + `/clients/${clientId}/tasks/${task.Id}`,
      task,
      { headers: { Authorization: token } }
    )
  },
  async DeleteClientTask(clientId, taskId) {
    const token = await getToken()
    return axios.delete(
      process.env.VUE_APP_API_ROOT + `/clients/${clientId}/tasks/${taskId}`,
      {
        headers: { Authorization: token }
      }
    )
  }
}
