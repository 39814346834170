const AdalMixin = {
  methods: {
    isAdmin() {
      return this.$adal.checkRoles(['Admin'])
    },
    isSalariedEntrepreneur() {
      return this.$adal.checkRoles(['ES_SALARIED'])
    }
  }
}

export default AdalMixin
