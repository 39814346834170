import * as State from './state-keys'
import * as Mutations from './mutation-types'
import { fillDocument } from './utils'

const mutations = {
  [Mutations.SET_USER_PROFILE](state, profile) {
    state[State.USER_PROFILE] = profile
  },
  [Mutations.SET_USER_FIRST_NAME](state, firstName) {
    state[State.USER_PROFILE].FirstName = firstName
  },
  [Mutations.SET_USER_LAST_NAME](state, lastName) {
    state[State.USER_PROFILE].LastName = lastName
  },
  [Mutations.SET_USER_HEALTH_NUMBER](state, healthNumber) {
    state[State.USER_PROFILE].HealthNumber = healthNumber
  },
  [Mutations.SET_USER_JOB_TITLE](state, jobTitle) {
    state[State.USER_PROFILE].JobTitle = jobTitle
  },
  [Mutations.SET_USER_EMAIL_ADDRESS](state, emailAddress) {
    state[State.USER_PROFILE].EmailAddress = emailAddress
  },
  [Mutations.SET_USER_PHONE_NUMBER](state, phoneNumber) {
    state[State.USER_PROFILE].PhoneNumber = phoneNumber
  },
  [Mutations.SET_USER_STREET_NAME](state, streetName) {
    state[State.USER_PROFILE].Address.StreetName = streetName
  },
  [Mutations.SET_USER_ZIP_CODE](state, zipCode) {
    state[State.USER_PROFILE].Address.ZipCode = zipCode
  },
  [Mutations.SET_USER_CITY](state, city) {
    state[State.USER_PROFILE].Address.City = city
  },
  [Mutations.SET_USER_COUNTRY](state, country) {
    state[State.USER_PROFILE].Address.Country = country
  },
  [Mutations.SET_USER_IS_AVAILABLE](state, isAvailable) {
    state[State.USER_PROFILE].IsAvailable = isAvailable
  },
  [Mutations.SET_USER_SHORT_BIO](state, shortBio) {
    state[State.USER_PROFILE].ShortBio = shortBio
  },
  [Mutations.SET_USER_SKILLS](state, skills) {
    state[State.USER_PROFILE].Skills = skills
    state[State.USER_PROFILE].Skills.sort()
  },
  [Mutations.SET_USER_ADR](state, adr) {
    state[State.USER_PROFILE].Adr = adr
  },
  [Mutations.SET_USER_PREFERRED_LOCATION](state, prefLocation) {
    state[State.USER_PROFILE].PreferredLocation = prefLocation
  },
  [Mutations.PUSH_USER_SKILL](state, skill) {
    state[State.USER_PROFILE].Skills.push(skill)
    state[State.USER_PROFILE].Skills.sort()
  },
  [Mutations.POP_USER_SKILL](state, skill) {
    const idx = state[State.USER_PROFILE].Skills.findIndex(s => s == skill)

    if (idx > -1) {
      state[State.USER_PROFILE].Skills.splice(idx, 1)
    }
  },
  [Mutations.SET_USER_PHOTO](state, photo) {
    fillDocument(state[State.USER_PROFILE].Photo, photo)
  },
  [Mutations.SET_USER_IDENTITY_CARD](state, doc) {
    fillDocument(state[State.USER_PROFILE].Documents.Identity, doc)
  },
  [Mutations.SET_USER_HEALTH_INSURANCE_EVIDENCE](state, doc) {
    fillDocument(
      state[State.USER_PROFILE].Documents.HealthInsuranceEvidence,
      doc
    )
  },
  [Mutations.SET_USER_CURRICULUM_VITAE](state, doc) {
    fillDocument(state[State.USER_PROFILE].Documents.CurriculumVitae, doc)
  },
  [Mutations.SET_USER_BANK_STATEMENT](state, doc) {
    fillDocument(state[State.USER_PROFILE].Documents.BankStatement, doc)
  },
  [Mutations.SET_ALL_SKILLS](state, skills) {
    state[State.ALL_SKILLS] = skills
    state[State.ALL_SKILLS].sort()
  },
  [Mutations.SET_CLIENTS](state, clients) {
    state[State.CLIENTS] = clients
  },
  [Mutations.REMOVE_CLIENT](state, id) {
    const idx = state[State.CLIENTS].findIndex(c => c.Id == id)

    if (idx > -1) {
      state[State.CLIENTS].splice(idx, 1)
    }
  },
  [Mutations.SET_PROJECTS](state, projects) {
    state[State.PROJECTS] = projects
  },
  [Mutations.SET_PROJECT](state, project) {
    const idx = state[State.PROJECTS].findIndex(p => p.Id == project.Id)

    if (idx == -1) {
      state[State.PROJECTS].push(project)
    } else {
      state[State.PROJECTS].splice(idx, 1, project)
    }
  },
  [Mutations.REMOVE_PROJECT](state, id) {
    const idx = state[State.PROJECTS].findIndex(p => p.Id == id)

    if (idx > -1) {
      state[State.PROJECTS].splice(idx, 1)
    }
  },
  [Mutations.SET_USERS](state, users) {
    state[State.USERS] = users
  },
  [Mutations.REMOVE_USER](state, upn) {
    const idx = state[State.USERS].findIndex(u => u.Upn == upn)

    if (idx > -1) {
      state[State.USERS].splice(idx, 1)
    }
  },
  [Mutations.SET_NOTIFICATIONS](state, notifications) {
    state[State.NOTIFICATIONS] = notifications
  },
  [Mutations.SET_ES_PHOTO](state, { upn, photo }) {
    const usr = state[State.USERS].find(u => u.Upn == upn)

    if (!!usr) {
      fillDocument(usr.Photo, photo)
    }
  }
}

export default mutations
