var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UserInfoBox',{attrs:{"box-label":"Profil pro","loading":_vm.loading,"hideSaveButton":!_vm.isDirty,"profilePro":""},on:{"user-saved":function($event){return _vm.onUserSaved($event)},"user-not-saved":function($event){return _vm.onUserNotSaved($event)}},scopedSlots:_vm._u([{key:"header",fn:function({ label }){return [_vm._v(" "+_vm._s(label)+" ")]}}])},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Disponibilité","label-for":"availability","label-cols-sm":"4","label-cols-lg":"3"}},[_c('b-checkbox',{staticClass:"my-2",attrs:{"id":"availability","switch":""},model:{value:(_vm.isAvailable),callback:function ($$v) {_vm.isAvailable=$$v},expression:"isAvailable"}})],1),_c('b-form-group',{staticClass:"block",attrs:{"label":"Mini bio","label-for":"short-bio"}},[_c('b-textarea',{staticClass:"my-2",attrs:{"id":"short-bio","rows":"5"},model:{value:(_vm.shortBio),callback:function ($$v) {_vm.shortBio=$$v},expression:"shortBio"}})],1),_c('b-form-group',{staticClass:"block",attrs:{"label":"Compétences","label-for":"skill-list-input"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"placeholder":"Ajoutez une compétence","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function({
          tags,
          placeholder,
          inputAttrs,
          inputHandlers,
          tagVariant,
          removeTag
        }){return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('fa-icon',{attrs:{"icon":"brain"}})],1),_c('b-form-input',_vm._g(_vm._b({attrs:{"id":"skill-list-input","placeholder":placeholder}},'b-form-input',inputAttrs,false),inputHandlers))],1),_c('b-form-text',{staticClass:"mb-2"},[_vm._v(" Appuyez sur ENTREE pour valider. ")]),_c('div',{staticClass:"d-inline-block w-100 text-left",staticStyle:{"font-size":"1.25rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-2",attrs:{"title":tag,"variant":tagVariant},on:{"remove":function($event){return _vm.onDeleteSkill({ skill: tag, removeTag })}}})}),1)]}}]),model:{value:(_vm.skills),callback:function ($$v) {_vm.skills=$$v},expression:"skills"}}),_c('b-form-group',{attrs:{"id":"adrGroup","label":"Tarif journalier","label-for":"dailyRate","label-cols-sm":"4","label-cols-lg":"3"}},[_c('b-form-input',{attrs:{"id":"dailyRate","type":"text","required":""},model:{value:(_vm.dailyRate),callback:function ($$v) {_vm.dailyRate=$$v},expression:"dailyRate"}})],1),_c('b-form-group',{attrs:{"id":"locationGroup","label":"Lieu de travail préféré","label-for":"prefLocation","label-cols-sm":"4","label-cols-lg":"3"}},[_c('b-form-input',{attrs:{"id":"prefLocation","type":"text","required":""},model:{value:(_vm.prefLocation),callback:function ($$v) {_vm.prefLocation=$$v},expression:"prefLocation"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }