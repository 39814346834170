<template>
  <b-container class="validation">
    <b-row>
      <b-col offset="6" class="text-right">
        <b-input
          type="search"
          v-model="search"
          placeholder="Salarié·e, UPN, poste..."
        />
        <b-form-text>
          <UserStatusFilter v-model="statusFilter" />
          <UserVisibilityFilter v-model="visibilityFilter" class="ml-3" />
        </b-form-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="users"
          :fields="fields"
          sort-by="Status"
          :filter="filter"
          :filter-included-fields="['Status']"
          :filter-function="filterFunction"
          :tbody-tr-class="rowClass"
          :busy="loading"
          class="mt-3"
        >
          <template #table-colgroup>
            <col width="auto" />
            <col width="100px" />
            <col width="70px" />
          </template>
          <template #table-busy><Loader /></template>
          <template #cell(ES)="{ item }">
            <UserCard :user="item" />
          </template>
          <template #cell(Status)="{ value, unformatted }">
            <b-badge :variant="getStatusVariant(unformatted)">{{
              value
            }}</b-badge>
          </template>
          <template #cell(Details)="{ detailsShowing, toggleDetails }">
            <div class="text-right">
              <span
                v-b-tooltip.hover
                :title="!detailsShowing ? 'Détails' : 'Masquer'"
                class="btn-icon mr-2"
              >
                <fa-icon
                  :icon="!detailsShowing ? 'chevron-down' : 'chevron-up'"
                  class="text-primary"
                  @click.stop="toggleDetails"
                />
              </span>
            </div>
          </template>
          <template #row-details="{ item }">
            <UserValidation :userProfile="item" @user-deleted="removeUser" />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { UserStatus } from '@/assets/js/Constants.js'
import { StringMixin, UserMixin } from '@/mixins'
import { mapState, mapActions } from 'vuex'
import { USERS } from '@/store/state-keys'
import { LOAD_USERS } from '@/store/action-types'
import { UserService } from '@/services'
import Loader from '@/components/Loader.vue'
import UserValidation from '@/components/User/UserValidation.vue'
import UserStatusFilter from '@/components/User/UserStatusFilter.vue'
import UserVisibilityFilter from '@/components/User/UserVisibilityFilter.vue'
import UserCard from '@/components/User/UserCard.vue'

export default {
  name: 'Validation',
  components: {
    Loader,
    UserValidation,
    UserStatusFilter,
    UserVisibilityFilter,
    UserCard
  },
  mixins: [StringMixin, UserMixin],
  data() {
    return {
      fields: [
        {
          key: 'ES',
          label: 'Salarié·e',
          sortable: true,
          formatter: this.formatESLabel,
          sortByFormatted: true
        },
        {
          key: 'Status',
          label: 'Statut',
          sortable: true,
          formatter: this.formatStatus
        },
        {
          key: 'Details',
          label: 'Détails'
        }
      ],
      loading: false,
      search: '',
      statusFilter: Object.values(UserStatus).join(','),
      visibilityFilter: undefined
    }
  },
  computed: {
    ...mapState([USERS]),
    users() {
      return this[USERS].map(u => ({ ...u }))
    },
    filter() {
      return `${this.search}|${this.statusFilter}|${this.visibilityFilter}`
    }
  },
  mounted() {
    this.loading = true

    this[LOAD_USERS]()
      .catch(error => {
        console.error('There was an error:', error)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions([LOAD_USERS]),
    rowClass(item) {
      if (!item) return
      if (item.Status != UserStatus.Validated) return 'table-warning'
    },
    removeUser(evtData) {
      // TODO: Handle user deletion in this view rather than in the details compoonent
    },
    onHideClick(user) {
      if (user.IsHiddenOnWebsite) {
        this.UnhideUser(user)
      } else {
        this.HideUser(user)
      }
    },
    HideUser(user) {
      UserService.SetUserWebsiteVisibility(user.Upn, false)
        .then(() => {
          user.IsHiddenOnWebsite = true
        })
        .catch(this.handleError)
    },
    UnhideUser(user) {
      UserService.SetUserWebsiteVisibility(user.Upn, true)
        .then(() => {
          user.IsHiddenOnWebsite = false
        })
        .catch(this.handleError)
    },
    handleError(err) {
      this.$emit(
        'failed',
        err.response
          ? new Error(err.response.data.Message, { cause: err })
          : err
      )
    },
    filterFunction(row, filter) {
      var { Upn, DisplayName, JobTitle, Status, IsHiddenOnWebsite } = row
      var [search, status, visible] = filter.split('|')

      return (
        (!search ||
          this.contains(DisplayName, search) ||
          this.contains(JobTitle, search) ||
          this.contains(Upn, search)) &&
        this.contains(status, '' + Status) &&
        (visible == 'undefined' ||
          (this.isES(row) && visible == '' + !IsHiddenOnWebsite))
      )
    },
    formatESLabel(_, __, user) {
      return user.DisplayName
    }
  }
}
</script>
