const ReadFileAsDataURL = file => {
  return new Promise((resolve, reject) => {
    try {
      const fr = new FileReader()

      fr.onload = e => resolve(e.target.result)
      fr.readAsDataURL(file)
    } catch (err) {
      reject(err)
    }
  })
}

const fileToDocument = file => {
  return new Promise((resolve, reject) => {
    try {
      const document = {
        FileName: file.name,
        Size: file.size,
        Type: file.type
      }

      const fr = new FileReader()
      fr.onload = (doc => {
        return e => {
          doc.Content = e.target.result
          resolve(document)
        }
      })(document)

      fr.readAsDataURL(file)
    } catch (err) {
      reject(err)
    }
  })
}

const isNullOrUndefined = object => object === null || object === undefined

export default { ReadFileAsDataURL, fileToDocument, isNullOrUndefined }
