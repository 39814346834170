<template>
  <div class="loader" :class="{ absolute: absolute }">
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    absolute: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  z-index: 2;
  width: 100%;
  height: 100%;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  z-index: 3;
  margin: auto auto;
  div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #033f3c;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      left: 45px;
      animation-delay: 0;
    }
  }
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
</style>
