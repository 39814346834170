<template>
  <div>
    <!-- <b-table
      ref="tasks"
      :items="tasks"
      :fields="fields"
      small
      caption-top
      @row-selected="onRowSelected"
      selectable
      select-mode="single"
    > -->
    <b-table
      ref="tasks"
      :items="tasks"
      :fields="fields"
      @row-clicked="onRowClicked"
      small
      caption-top
      hover
    >
      <template #table-caption>
        <b-button variant="primary" size="sm" @click="onAddTask"
          ><fa-icon icon="plus" /> Ajouter une tâche</b-button
        >
      </template>
      <template #cell(Date)="{ value, rowSelected }">
        <template v-if="!rowSelected">
          {{ value }}
        </template>
        <b-form-datepicker
          v-else
          v-model="editTask.Date"
          locale="fr-FR"
          v-bind="dpLabels"
          start-weekday="1"
          label-help=""
          size="sm"
          hide-header
        />
      </template>
      <template #cell(Title)="{ value, rowSelected }">
        <template v-if="!rowSelected">
          {{ value }}
        </template>
        <b-input v-else v-model="editTask.Title" size="sm" />
      </template>
      <template #cell(Status)="{ value, rowSelected }">
        <template v-if="!rowSelected">
          {{ value }}
        </template>
        <b-checkbox v-else v-model="editTaskDone" />
      </template>
      <template #cell(AssignedTo)="{ value, rowSelected }">
        <template v-if="!rowSelected">
          {{ value }}
        </template>
        <b-select
          v-else
          v-model="editTask.AssignedTo"
          :options="adminOptions"
          size="sm"
        />
      </template>
    </b-table>
    <b-modal id="modalTask" title="Tâche" @ok="onConfirmTaskEdit">
      <b-form ref="taskForm" :validated="taskFormValidated" novalidate>
        <b-form-row>
          <b-col>
            <b-form-group label="Nom">
              <b-input v-model="editTask.Title" required />
            </b-form-group>
            <b-form-group label="Date">
              <date-picker
                v-model="editTaskDate"
                :config="configs.picker.date"
                required
              />
            </b-form-group>
            <b-form-group label="Description">
              <b-textarea v-model="editTask.Comment" rows="3" max-rows="5" />
            </b-form-group>
            <b-form-group label="Fait">
              <b-checkbox v-model="editTaskDone" />
            </b-form-group>
            <b-form-group label="Assignée à">
              <b-select v-model="editTask.AssignedTo" :options="adminOptions" />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-show="!isCreateMode"
          variant="danger"
          class="mr-auto"
          @click="onDeleteTask"
          ><fa-icon icon="trash-alt" /> Supprimer</b-button
        >
        <b-button variant="secondary" @click="cancel()"
          ><fa-icon icon="ban" /> Annuler</b-button
        >
        <b-button variant="primary" @click="ok()"
          ><fa-icon icon="edit" /> Valider</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import { DateMixin, DateTimePickerConfigMixin } from '@/mixins'
import { ClientService } from '@/services'

export default {
  name: 'ClientTasks',
  mixins: [DateMixin, DateTimePickerConfigMixin],
  props: {
    clientId: { type: String, required: true }
  },
  data() {
    return {
      tasks: [],
      editTask: {},
      taskFormValidated: false,
      adminOptions: [
        { text: 'Non assignée', value: 'Unassigned' },
        { text: 'Afid', value: 'aghaz@insiders.coop' },
        { text: 'Alexandre', value: 'achemla@insiders.coop' },
        { text: 'Camille', value: 'ccalves@insiders.coop' },
        { text: 'Charles-Henri', value: 'chsauget@indiers.coop' },
        { text: 'Jessica', value: 'jrobles@insiders.coop' }
      ],
      fields: [
        {
          key: 'Date',
          formatter: v => new Date(v).toLocaleDateString()
        },
        {
          key: 'Title',
          label: 'Nom'
        },
        {
          key: 'Status',
          label: 'Fait',
          formatter: v => (v == 1 ? '✓' : '')
        },
        {
          key: 'AssignedTo',
          label: 'Assignée à',
          formatter: v => this.adminOptions.find(o => o.value == v).text
        }
      ],
      dpLabels: {
        //labelPrevDecade: 'Vorheriges Jahrzehnt',
        labelPrevYear: 'Année précédente',
        labelPrevMonth: 'Mois précédent',
        labelCurrentMonth: 'Mois courant',
        labelNextMonth: 'Mois suivant',
        labelNextYear: 'Année suivante',
        //labelNextDecade: 'Nächstes Jahrzehnt',
        labelToday: "Aujourd'hui"
        //labelSelected: 'Ausgewähltes Datum',
        // labelNoDateSelected: 'Kein Datum gewählt',
        // labelCalendar: 'Kalender',
        // labelNav: 'Kalendernavigation',
        // labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
      }
    }
  },
  computed: {
    editTaskDate: {
      get() {
        return new Date(this.editTask.Date)
      },
      set(value) {
        this.editTask.Date = this.parseDate(value).toJSON()
      }
    },
    editTaskDone: {
      get() {
        return this.editTask.Status == 1
      },
      set(done) {
        this.editTask.Status = done ? 1 : 0
      }
    },
    isCreateMode() {
      return !this.editTask.Id
    }
  },
  mounted() {
    ClientService.GetClientTasks(this.clientId)
      .then(
        response =>
          (this.tasks = response.data.sort((t1, t2) =>
            t2.Date.localeCompare(t1.Date)
          ))
      )
      .catch(err => console.error('There was an error:', err))
  },
  methods: {
    onAddTask() {
      this.editTask = {
        ClientId: this.clientId,
        Comment: '',
        Status: 0,
        AssignedTo: 'Unassigned'
      }
      this.$bvModal.show('modalTask')
    },
    onRowClicked(item) {
      this.editTask = { ...item }
      this.$bvModal.show('modalTask')
    },
    onConfirmTaskEdit(evt) {
      evt.preventDefault()

      this.taskFormValidated = true

      if (this.$refs.taskForm.checkValidity()) {
        const prom = this.isCreateMode
          ? ClientService.CreateClientTask(this.clientId, this.editTask).then(
              response => {
                this.tasks.push(response.data)
                this.tasks.sort((t1, t2) => t2.Date.localeCompare(t1.Date))
              }
            )
          : ClientService.SetClientTask(this.clientId, this.editTask).then(
              () => {
                const idx = this.tasks.findIndex(t => t.Id == this.editTask.Id)

                if (idx != -1) {
                  this.tasks.splice(idx, 1, { ...this.editTask })
                  this.tasks.sort((t1, t2) => t2.Date.localeCompare(t1.Date))
                }
              }
            )

        prom
          .then(() => {
            this.$bvModal.hide('modalTask')
            this.$nextTick(function () {
              this.editTask = {}
              this.taskFormValidated = false
            })
          })
          .catch(err => console.error(err))
      }
    },
    onDeleteTask() {
      ClientService.DeleteClientTask(this.clientId, this.editTask.Id)
        .then(() => {
          const idx = this.tasks.findIndex(t => t.Id == this.editTask.Id)

          if (idx != -1) {
            this.tasks.splice(idx, 1)
            this.tasks.sort((t1, t2) => t2.Date.localeCompare(t1.Date))
          }

          this.$bvModal.hide('modalTask')
          this.$nextTick(function () {
            this.editTask = {}
          })
        })
        .catch(err => console.error(err))
    }
    //,
    // onRowSelected(rows) {
    //   console.debug(rows)

    //   if (rows.length) {
    //     this.editTask = { ...rows[0] }
    //   } else {
    //     ClientService.SetClientTask(this.clientId, this.editTask)
    //       .then(() => {
    //         const idx = this.tasks.findIndex(t => (t.Id = this.editTask.Id))

    //         if (idx != -1) {
    //           this.tasks.splice(idx, 1, { ...this.editTask })
    //         }
    //       })
    //       .catch(err => console.error(err))
    //   }
    // }
  }
}
</script>
