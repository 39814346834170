<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-row>
      <b-col>
        <b-form-group label="Nom" horizontal>
          <b-input ref="nameInput" v-model="project.Name" required />
        </b-form-group>
        <b-form-group label="Client" horizontal>
          <LiveSearchSelect
            v-model="project.ClientId"
            :items="clients"
            required
          />
        </b-form-group>
        <b-form-group label="Description" horizontal>
          <b-form-textarea v-model="project.Description" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="8">
        <b-form-group label="# Bon de commande" horizontal>
          <b-input v-model="project.PurchaseOrder" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Nombre de jours" horizontal>
          <b-input
            type="number"
            v-model="project.DayCount"
            min="0"
            step="any"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Début" horizontal>
          <date-picker
            ref="startPicker"
            v-model="startDate"
            :config="configs.picker.date"
            required
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Fin" horizontal>
          <date-picker
            ref="endPicker"
            v-model="endDate"
            :config="configs.picker.date"
            required
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="8">
        <b-form-group label="Prix" horizontal>
          <b-input type="number" v-model="project.Price" min="0" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="TVA (%)" horizontal>
          <b-input type="number" v-model="project.Vat" min="0" step="0.01" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Échéance (jours)" horizontal>
          <b-input type="number" v-model="project.DueDays" min="0" />
        </b-form-group>
        <b-form-group
          v-show="$adal.checkRoles(['Admin'])"
          label="Intervenant"
          horizontal
        >
          <b-form-select v-model="upn" :options="users" required />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { DateMixin, DateTimePickerConfigMixin } from '@/mixins'
import { Project } from '@/types'
import LiveSearchSelect from '@/components/Tool/LiveSearchSelect.vue'

export default {
  name: 'ProjectDetails',
  components: { LiveSearchSelect },
  mixins: [DateMixin, DateTimePickerConfigMixin],
  props: {
    project: { type: Project, required: true },
    projects: { type: Array, required: true },
    clients: { type: Array, required: true },
    users: { type: Array, required: true }
  },
  data() {
    return {
      validated: false
    }
  },
  computed: {
    startDate: {
      get() {
        return new Date(this.project.StartDate)
      },
      set(value) {
        this.project.StartDate = this.parseDate(value)
      }
    },
    endDate: {
      get() {
        return new Date(this.project.EndDate)
      },
      set(value) {
        this.project.EndDate = this.parseDate(value)
      }
    },
    upn: {
      get() {
        return this.project.Upn == null
          ? this.$adal.user.profile.upn
          : this.project.Upn
      },
      set(value) {
        this.project.Upn = value
      }
    }
  },
  mounted() {
    this.project.Upn =
      this.project.Upn == null ? this.$adal.user.profile.upn : this.project.Upn
    this.project.Vat = this.project.Vat == null ? 20 : this.project.Vat
    this.project.DueDays =
      this.project.DueDays == null ? 30 : this.project.DueDays
  },
  methods: {
    checkDateConsistency() {
      return this.endDate > this.startDate
    },
    checkProjectNameUnicity() {
      return !this.projects.find(
        p =>
          this.project.Id != p.Id &&
          p.Upn == this.upn &&
          p.Name == this.project.Name
      )
    },
    validate() {
      this.validated = false
      this.$refs.startPicker.$el.setCustomValidity('')
      this.$refs.endPicker.$el.setCustomValidity('')
      this.$refs.nameInput.$el.setCustomValidity('')

      let valid = this.$refs.form.checkValidity()

      if (!this.checkDateConsistency()) {
        const msg = 'La date de fin doit être postérieure à la date de début.'
        this.$refs.startPicker.$el.setCustomValidity(msg)
        this.$refs.endPicker.$el.setCustomValidity(msg)

        valid = false
      }

      if (!this.checkProjectNameUnicity()) {
        const msg = 'Le nom du projet doit être unique par intervenant.'
        this.$refs.nameInput.$el.setCustomValidity(msg)

        valid = false
      }

      this.validated = true

      return valid
    }
  }
}
</script>
