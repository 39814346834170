<template>
  <div class="live-search-select">
    <div class="live-search-select-input">
      <b-input
        type="search"
        ref="input"
        v-model="selectedText"
        class="custom-select"
        :required="required"
        @focus="onFocus"
        @blur="onBlur"
        autocomplete="off"
        role="autocomplete"
      />
      <div
        v-if="showOptions"
        class="live-search-select-input-options text-right"
      >
        <b-badge
          v-for="option in options"
          :key="option.Id"
          :variant="
            valueSelector(option) == selectedValue ? 'primary' : 'light'
          "
          v-b-tooltip.hover
          :title="option.altText"
          @click="selectItem(option, true)"
        >
          {{ textSelector(option) }}
        </b-badge>
      </div>
    </div>
    <b-list-group
      class="live-search-select-list shadow-sm"
      :class="{ 'live-search-select-list--hidden': !listVisible }"
      @blur="onBlur"
    >
      <b-list-group-item
        v-for="(item, idx) in filteredItems"
        :key="keySelector(item, idx)"
        :button="isButton(item)"
        :disabled="!!item.disabled"
        :active="valueSelector(item) == selectedValue"
        role="listItem"
        @click.stop="selectItem(item)"
      >
        <slot
          name="list-item"
          :item="item"
          :text="textSelector(item)"
          :altText="altTextSelector(item)"
        >
          {{ textSelector(item) }}
          <template v-if="altTextSelector(item)">
            <br /><small>{{ altTextSelector(item) }}</small>
          </template>
        </slot>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import Utils from '@/assets/js/Utils.js'
import { StringMixin } from '@/mixins'

const NO_RESULT = { value: null, text: 'Pas de résultat' }

export default {
  name: 'LiveSearchSelect',
  mixins: [StringMixin],
  model: {
    prop: 'selectedValue',
    event: 'itemSelected'
  },
  props: {
    items: { type: Array, required: true },
    options: { type: Array, default: () => [] },
    keySelector: {
      type: Function,
      default: (i, idx) =>
        !Utils.isNullOrUndefined(i.key)
          ? i.key
          : !Utils.isNullOrUndefined(i.value)
          ? i.value
          : idx
    },
    textSelector: {
      type: Function,
      default: i => (!Utils.isNullOrUndefined(i.text) ? i.text : i.toString())
    },
    altTextSelector: {
      type: Function,
      default: i => i.altText
    },
    valueSelector: { type: Function, default: i => (i.value ? i.value : i) },
    selectedValue: { type: [Object, String] },
    required: { type: Boolean }
  },
  data() {
    return {
      search: this.selectedText,
      focus: false
    }
  },
  computed: {
    selectedItem() {
      return this.items.find(i => this.valueSelector(i) == this.selectedValue)
    },
    selectOption() {
      return this.options.find(i => this.valueSelector(i) == this.selectedValue)
    },
    selectedText: {
      get() {
        return this.selectedItem
          ? this.textSelector(this.selectedItem)
          : this.selectOption
          ? `${this.textSelector(this.selectOption)} - ${this.altTextSelector(
              this.selectOption
            )}`
          : this.search
      },
      set(value) {
        this.search = value
      }
    },
    filteredItems() {
      const f = this.items.filter(
        i => !this.search || this.contains(this.textSelector(i), this.search)
      )

      if (f.length == 0) {
        f.push(NO_RESULT)
      }

      return f
    },
    listVisible() {
      return this.focus && this.items.length > 0
    },
    showOptions() {
      return this.options.length > 0
    }
  },
  methods: {
    isButton(item) {
      return item != NO_RESULT
    },
    onFocus(event) {
      this.focus = true
    },
    onBlur(event) {
      const target = event.relatedTarget
      if (
        target &&
        ['autocomplete', 'listItem'].indexOf(target.attributes?.role?.value) !==
          -1
      )
        return
      this.focus = false
    },
    selectItem(item, isOption = false) {
      if (item != NO_RESULT) {
        this.$emit('itemSelected', this.valueSelector(item))
        this.search = isOption
          ? `${this.textSelector(item)} - ${this.altTextSelector(item)}`
          : this.textSelector(item)
        this.$refs.input.blur()
        this.focus = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.live-search-select {
  position: relative;

  &-input-options .badge {
    cursor: pointer;
  }

  &-list {
    position: absolute;
    z-index: 999;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;

    &--hidden {
      animation-name: hide;
      animation-fill-mode: both;
      animation-duration: 0.3s;
      animation-timing-function: linear;
    }
  }
}

@keyframes hide {
  to {
    visibility: hidden;
    display: none;
  }
}
</style>
