const USERS = `${process.env.VUE_APP_API_ROOT}/users`
const USER = upn => `${USERS}/${upn}`
const USER_PHOTO = upn => `${USER(upn)}/photo`

const USER_VALIDATE_INFO = `${process.env.VUE_APP_API_ROOT}/ValidateUserInfo`
const USER_REJECT_INFO = `${process.env.VUE_APP_API_ROOT}/RejectUserInfo`
const USER_VALIDATE_PHOTO = `${process.env.VUE_APP_API_ROOT}/ValidateUserPHOTO`
const USER_REJECT_PHOTO = `${process.env.VUE_APP_API_ROOT}/RejectUserIPhoto`
const USER_DOCUMENT = `${process.env.VUE_APP_API_ROOT}/GetUserDocument`
const USER_DOCUMENT_VALIDATE = `${process.env.VUE_APP_API_ROOT}/ValidateUserDocument`
const USER_DOCUMENT_REJECT = `${process.env.VUE_APP_API_ROOT}/RejectUserDocument`
const USER_DOCUMENT_SAVE = `${process.env.VUE_APP_API_ROOT}/SaveUserDocument`

const MY_PROFILE = `${process.env.VUE_APP_API_ROOT}/me/profile`
const MY_PROFILE_PRO = `${process.env.VUE_APP_API_ROOT}/me/profile-pro`
const MY_SKILL = skill => `${process.env.VUE_APP_API_ROOT}/me/skills/${skill}`

const USER_ADMINISTRATIVE_INFO = upn => `${USER(upn)}/administrative-info`
const USER_HIDDEN_FROM_WEBSITE = upn => `${USER(upn)}/hidden-from-website`

const SKILLS = `${process.env.VUE_APP_API_ROOT}/skills`
const USER_SKILLS = upn => `${USER(upn)}/skills`

const NOTIFICATIONS = `${process.env.VUE_APP_API_ROOT}/notifications`

export {
  USERS,
  USER,
  USER_PHOTO,
  USER_VALIDATE_INFO,
  USER_REJECT_INFO,
  USER_VALIDATE_PHOTO,
  USER_REJECT_PHOTO,
  USER_DOCUMENT,
  USER_DOCUMENT_VALIDATE,
  USER_DOCUMENT_REJECT,
  USER_DOCUMENT_SAVE,
  MY_PROFILE,
  MY_PROFILE_PRO,
  MY_SKILL,
  USER_ADMINISTRATIVE_INFO,
  USER_HIDDEN_FROM_WEBSITE,
  SKILLS,
  USER_SKILLS,
  NOTIFICATIONS
}
