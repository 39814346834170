var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-row',[_c('b-col',[_c('div',{attrs:{"id":"calendar"}},[_c('Calendar',{ref:"calendar",on:{"events-loading":function($event){_vm.loading = true},"events-loaded":function($event){_vm.loading = false
            _vm.slots = $event}}}),_c('b-alert',{staticClass:"text-center mt-3 mb-0",attrs:{"variant":"success","show":""}},[_c('dl',{staticClass:"row mb-0"},[_c('dd',{staticClass:"col-3 text-right mb-0"},[_vm._v("Imputation :")]),_c('dt',{staticClass:"col-3 text-left mb-0"},[_vm._v(" "+_vm._s(_vm.imputedDayCount)+" j / "+_vm._s((_vm.$refs.calendar || {}).workingDayCount)+" jours ouvrés ")]),_c('dd',{staticClass:"col-3 text-right mb-0"},[_vm._v("CA "),_c('u',[_vm._v("estimé")]),_vm._v(" :")]),_c('dt',{staticClass:"col-3 text-left mb-0"},[_vm._v(" "+_vm._s(_vm.estimatedGrossRevenue)+" € "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right",attrs:{"title":"Estimation basée sur le tarif renseigné dans les propriétés\n                  des projets concernés."}},[_c('fa-icon',{attrs:{"icon":"info-circle"}})],1)])])]),_c('b-table',{attrs:{"items":_vm.aggregatedSlots,"fields":_vm.config.table.aggregatedFields,"sort-by":"id","busy":_vm.loading,"hover":""},on:{"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('Loader')]},proxy:true},{key:"table-colgroup",fn:function(){return [_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"100px"}})]},proxy:true},{key:"cell(realDuration)",fn:function({ item, value }){return [_vm._v(" "+_vm._s(value)),(item.duration != item.realDuration)?_c('sup',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticStyle:{"opacity":"0.5"},attrs:{"title":`<strong>${value} ${
                value <= 1 ? 'jour' : 'jours'
              }</strong> ce mois-ci sur <strong>${
                item.duration
              }</strong> en tout.`}},[_vm._v("?")]):_vm._e()]}},{key:"cell(realRevenue)",fn:function({ item, value }){return [_vm._v(" "+_vm._s(value)+" €"),(value != item.revenue)?_c('sup',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticStyle:{"opacity":"0.5"},attrs:{"title":`<strong>${value} €</strong> ce mois-ci sur <strong>${item.revenue} €</strong> en tout.`}},[_vm._v("?")]):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('b-table',{attrs:{"items":row.item.slots,"fields":_vm.config.table.fields,"busy":_vm.loading,"small":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('Loader')]},proxy:true},{key:"table-colgroup",fn:function(){return [_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"100px"}})]},proxy:true},{key:"cell(realDuration)",fn:function({ item, value }){return [_vm._v(" "+_vm._s(value)),(item.duration != item.realDuration)?_c('sup',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticStyle:{"opacity":"0.5"},attrs:{"title":`<strong>${value} ${
                    value <= 1 ? 'jour' : 'jours'
                  }</strong> ce mois-ci sur <strong>${
                    item.duration
                  }</strong> en tout.`}},[_vm._v("?")]):_vm._e()]}},{key:"cell(realRevenue)",fn:function({ item, value }){return [_vm._v(" "+_vm._s(value)+" €"),(value != item.revenue)?_c('sup',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",modifiers:{"html":true}}],staticStyle:{"opacity":"0.5"},attrs:{"title":`<strong>${value} €</strong> ce mois-ci sur <strong>${item.revenue} €</strong> en tout.`}},[_vm._v("?")]):_vm._e()]}}],null,true)})]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }