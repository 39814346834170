import axios from 'axios'
import getToken from './adal.js'
import TechnicalSvc from './Technical'
import { UserRoutes } from './routes'

export default {
  async GetUsers() {
    const token = await getToken()
    return axios.get(UserRoutes.USERS, {
      headers: { Authorization: token }
    })
  },
  async GetUserPhoto(upn) {
    const token = await getToken()
    return axios.get(UserRoutes.USER_PHOTO(upn), {
      headers: { Authorization: token }
    })
  },
  async DeleteUser(upn) {
    const token = await getToken()
    return axios.delete(UserRoutes.USER(upn), {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async ValidateUserInfo(upn) {
    const token = await getToken()
    return axios.patch(UserRoutes.USER_VALIDATE_INFO, upn, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async RejectUserInfo(upn) {
    const token = await getToken()
    return axios.patch(UserRoutes.USER_REJECT_INFO, upn, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async ValidateUserPhoto(upn) {
    const token = await getToken()
    return axios.patch(UserRoutes.USER_REJECT_PHOTO, upn, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async RejectUserPhoto(upn) {
    const token = await getToken()
    return axios.patch(UserRoutes.USER_REJECT_PHOTO, upn, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async GetUserDocument(upn, docType) {
    const token = await getToken()
    return axios.post(
      UserRoutes.USER_DOCUMENT,
      {
        Upn: upn,
        DocumentType: docType
      },
      {
        responseType: 'blob',
        headers: { Authorization: token }
      }
    )
  },
  async ValidateUserDocument(upn, docType) {
    const token = await getToken()
    return axios.patch(
      UserRoutes.USER_DOCUMENT_VALIDATE,
      {
        Upn: upn,
        DocumentType: docType
      },
      {
        headers: { Authorization: token }
      }
    )
  },
  async RejectUserDocument(upn, docType) {
    const token = await getToken()
    return axios.patch(
      UserRoutes.USER_DOCUMENT_REJECT,
      {
        Upn: upn,
        DocumentType: docType
      },
      {
        headers: { Authorization: token }
      }
    )
  },
  async GetUserProfile() {
    const token = await getToken()
    return axios.get(UserRoutes.MY_PROFILE, {
      headers: { Authorization: token }
    })
  },
  async SetUserProfile(userProfile) {
    const token = await getToken()
    return axios.post(UserRoutes.MY_PROFILE, userProfile, {
      headers: { Authorization: token }
    })
  },
  async SetUserProfilePro(userProfilePro) {
    const token = await getToken()
    return axios.put(UserRoutes.MY_PROFILE_PRO, userProfilePro, {
      headers: { Authorization: token }
    })
  },
  async SetUserAdministrativeInfo(upn, adminInfo) {
    const token = await getToken()
    return axios.put(UserRoutes.USER_ADMINISTRATIVE_INFO(upn), adminInfo, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async SetUserWebsiteVisibility(upn, visible) {
    const token = await getToken()
    return axios.put(UserRoutes.USER_HIDDEN_FROM_WEBSITE(upn), !visible, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin(),
        'Content-Type': 'application/json'
      }
    })
  },
  async SaveDocument(file) {
    const token = await getToken()
    let formData = new FormData()
    formData.append('file', file, file.name)

    return axios.post(UserRoutes.USER_DOCUMENT_SAVE, formData, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async PutMySkill(skill) {
    const token = await getToken()
    return axios.put(UserRoutes.MY_SKILL(skill), undefined, {
      headers: { Authorization: token }
    })
  },
  async DeleteMySkill(skill) {
    const token = await getToken()
    return axios
      .delete(UserRoutes.MY_SKILL(skill), {
        headers: { Authorization: token }
      })
      .catch(err => {
        if (err.response && err.response.status != 404) {
          throw err
        }
      })
  },
  async GetAllSkills() {
    const token = await getToken()
    return axios.get(UserRoutes.SKILLS, {
      headers: { Authorization: token }
    })
  },
  async GetUserSkills(upn) {
    const token = await getToken()
    return axios.get(UserRoutes.USER_SKILLS(upn), {
      headers: { Authorization: token }
    })
  },
  async GetNotifications() {
    const token = await getToken()
    return axios.get(UserRoutes.NOTIFICATIONS, {
      headers: { Authorization: token }
    })
  }
}
