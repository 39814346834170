<template>
  <!-- <b-tabs v-model="activeTab" @input="onTabIndexChanged">
    <b-tab title="Caméra" name active>
      <b-container>
        <b-row no-gutters>
          <b-col @click="onCapture">
            <WebCam
              ref="webcam"
              :device-id="deviceId"
              width="100%"
              @started="onStarted"
              @stopped="onStopped"
              @error="onError"
              @cameras="onCameras"
              @camera-change="onCameraChange"
              @video-live="onVideoLive"
            />
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <b-form-select v-model="deviceId">
              <option
                v-for="device in devices"
                :key="device.deviceId"
                :value="device.deviceId"
                >{{ device.label }}</option
              >
            </b-form-select>
          </b-col>
        </b-row>
      </b-container>
    </b-tab>
    <b-tab title="Galerie"> -->
  <b-container>
    <b-row no-gutters>
      <b-col @click="$refs.fileInput.click()">
        <img v-if="isFileImage" :src="dataURL" class="gallery" />
        <!-- <embed v-else-if="isFilePdf" type="application/pdf" :src="dataURL" class="gallery" /> -->
        <fa-icon v-else :icon="galleryIcon" class="gallery" />
        <input
          v-show="false"
          ref="fileInput"
          type="file"
          accept="image/*, application/pdf, .pdf"
          @change="onPhotoChange"
        />
      </b-col>
    </b-row>
  </b-container>
  <!-- </b-tab>
  </b-tabs> -->
</template>
<script>
// import { WebCam } from 'vue-web-cam'
import Utils from '@/assets/js/Utils.js'

const tabIndexes = {
  CAMERA: 0,
  GALLERY: 1
}

export default {
  name: 'Camera',
  // components: { WebCam },
  model: {
    prop: 'picture',
    event: 'picture-taken'
  },
  props: {
    picture: { type: String }
  },
  data() {
    return {
      // activeTab: 0,
      // deviceId: null,
      // devices: [],
      // cameraReady: false,
      file: undefined,
      dataURL: undefined
    }
  },
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId)
    },
    galleryIcon() {
      return this.isFileImage
        ? 'file-image'
        : this.isFilePdf
        ? 'file-pdf'
        : 'camera'
    },
    isFileImage() {
      return this.file && this.file.type.startsWith('image/')
    },
    isFilePdf() {
      return (
        this.file &&
        (this.file.type == 'application/pdf' || this.file.name == '.pdf')
      )
    },
    galleryReady() {
      return !!this.isFileImage || !!this.isFilePdf
    }
  },
  // watch: {
  //   cameraReady(value, old) {
  //     console.log('[CAMERA] ready state assigned: ', value)

  //     if (value !== old) {
  //       console.log('[CAMERA] ready state changed: ', value)
  //       this.emitReadyStateChanged()
  //     }
  //   }
  // },
  // mounted() {
  //   console.log('[CAMERA::mounted]')
  //   this.cameraReady = false
  // },
  // activated() {
  //   console.log('[CAMERA::activated]')
  //   this.cameraReady = false
  // },
  // deactivated() {
  //   console.log('[CAMERA::deactivated]')
  //   this.cameraReady = false
  // },
  // beforeDestroy() {
  //   console.log('[CAMERA::beforeDestroy]')
  //   this.cameraReady = false
  // },
  // destroyed() {
  //   console.log('[CAMERA::destroyed]')
  //   this.cameraReady = false
  // },
  methods: {
    capture() {
      this.onCapture()
    },
    onCapture() {
      if (this.activeTab == tabIndexes.CAMERA) {
        this.$emit('picture-taken', this.$refs.webcam.capture())
      } else {
        this.$emit('picture-taken', this.dataURL)
      }
    },
    // onStarted(/*stream*/) {
    //   console.log('[CAMERA::started]')
    //   this.cameraReady = false
    // },
    // onStopped(/*stream*/) {
    //   console.log('[CAMERA::stopped]')
    //   this.cameraReady = false
    // },
    // onError(error) {
    //   console.log('[CAMERA::error]', error)
    //   this.cameraReady = false
    // },
    // onCameras(cameras) {
    //   this.devices = cameras

    //   if (cameras.length) {
    //     this.deviceId = cameras[0].deviceId
    //   }
    // },
    // onCameraChange(deviceId) {
    //   console.log('[CAMERA::camera-change]', deviceId)

    //   this.deviceId = deviceId
    //   this.cameraReady = false
    // },
    // onVideoLive(/*strm*/) {
    //   console.log('[CAMERA::video-live]')
    //   this.cameraReady = true
    // },
    // onTabIndexChanged() {
    //   this.emitReadyStateChanged()
    // },
    emitReadyStateChanged() {
      const state =
        // this.activeTab == tabIndexes.CAMERA
        //   ? this.cameraReady
        //   :
        this.galleryReady
      console.log('[CAMERA::emitReadyStateChanged]: ', state)
      this.$emit('ready-state-changed', state)
    },
    async onPhotoChange(event) {
      const { files } = event.target

      if (files && files[0] && this.checkType(files[0])) {
        this.file = files[0]
        this.dataURL = await Utils.ReadFileAsDataURL(files[0])
      }

      this.emitReadyStateChanged()
    },
    checkType({ type, name }) {
      return (
        type.startsWith('image/') ||
        type == 'application/pdf' ||
        name.endsWith('.pdf')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  width: 100%;
  height: 400px;
  color: gray;
  cursor: pointer;

  &:hover {
    color: black;
  }
}

img.gallery {
  object-fit: contain;
}

svg.gallery {
  padding: 20px;
}
</style>
